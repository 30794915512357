import { JSX, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Icon from '../Icon/Icon';
import styles from './toast.module.scss';

interface CloseButtonProps {
  closeToast: (e: MouseEvent<HTMLElement>) => void;
}

const Toast = (): JSX.Element => {
  const CloseButton = ({ closeToast }: CloseButtonProps): JSX.Element => {
    const { t } = useTranslation('global');

    return (
      <button
        type='button'
        onClick={closeToast}>
        <Icon
          iconName='close'
          iconTitle={t('ctaClose', { name: t('notification') })} />
      </button>
    );
  };

  return <ToastContainer
    className={styles.bToast}
    position='bottom-left'
    autoClose={5000}
    hideProgressBar
    closeOnClick
    pauseOnHover
    stacked
    draggable={false}
    closeButton={CloseButton}/>;
};


export default Toast;
