import { queryFetcher } from 'services/services';
import { Cart } from 'types/cart';
import { Method, PaymentSessionStatusType, PaymentSessionType } from 'types/checkout';
import { NavigationItem } from 'types/navigation';
import { UserOrder } from 'types/order';
import { FeaturedProduct, ListProduct, Product } from 'types/products';
import { StaticSnippet } from 'types/static';
import { User, Country } from 'types/user';

export function fetchCategories(): Promise<NavigationItem[]> {
  return queryFetcher<NavigationItem[]>('categories?');
}

export function fetchProducts(optionsQuery: string): Promise<ListProduct[]> {
  return queryFetcher<ListProduct[]>(`products?${optionsQuery}`);
}

export function fetchProduct(id?: string): Promise<Product> {
  return queryFetcher<Product>(`products/${id}?`);
}

export function fetchProductSearch(query: string): Promise<ListProduct[]> {
  return queryFetcher<ListProduct[]>(`products?q=${query}&`);
}

export function fetchPage(page?: string): Promise<StaticSnippet> {
  return queryFetcher<StaticSnippet>(`pages/${page || ''}?`);
}

export function fetchUser(): Promise<User> {
  return queryFetcher<User>('user/info?');
}

export function fetchAddresses(): Promise<User['addresses']> {
  return queryFetcher<User['addresses']>('user/addresses?');
}

export function fetchUserOrders(id?: string | null): Promise<UserOrder[] | UserOrder> {
  const orderIdParam = id ? `/${id}` : '';

  return queryFetcher<UserOrder[] | UserOrder>(`user/orders${orderIdParam}?`);
}

export function fetchCountries(): Promise<Country[]> {
  return queryFetcher<Country[]>('countries?');
}

export function fetchCart(id?: string): Promise<Cart> {
  return queryFetcher<Cart>(`shopping-cart/${id}?`);
}

export function fetchShippingMethods(countryId?: string): Promise<Method[]> {
  return queryFetcher<Method[]>(`shipping-methods?country=${countryId}&`);
}

export function fetchPaymentMethods(): Promise<Method[]> {
  return queryFetcher<Method[]>('payment-methods?');
}

export function fetchFeaturedProduct(): Promise<FeaturedProduct> {
  return queryFetcher<FeaturedProduct>('featured-item?');
}

export function fetchPaymentSession(orderId?: string): Promise<PaymentSessionType> {
  return queryFetcher<PaymentSessionType>(`user/orders/${orderId}/checkout?`);
}

export function fetchPaymentSessionStatus(orderId?: string, sessionId?: string): Promise<PaymentSessionStatusType> {
  return queryFetcher<PaymentSessionStatusType>(`user/orders/${orderId}/checkout/status?sessionId=${sessionId}&`);
}
