import classNames from 'classnames';
import { JSX } from 'react';
import { FormattedNumber } from 'react-intl';
import { OrderItemsType } from 'types/order';
import { isNotEmpty } from 'utils/validation';
import styles from './orderItems.module.scss';
import { useOrderItems } from './useOrderItems';

interface OrderItemProps {
  orderItems: OrderItemsType[];
  isCheckout?: boolean;
}

const OrderItems = ({ orderItems, isCheckout }: OrderItemProps): JSX.Element => {
  const { t } = useOrderItems();
  const orderItemsClasses = classNames(styles.bOrderItems, {
    [styles.xCheckout]: isCheckout
  });

  return (
    <div className={orderItemsClasses} key={`${isCheckout ? 'checkoutItems' : 'orderItems'}`}>
      {!isCheckout && <h2 className={styles.title}>{t('orders.itemsOrdered')}</h2>}
      <ul className={styles.items}>
        {orderItems.map(item => (
          <li key={item.id} className={styles.item}>
            <img
              className={styles.itemImage}
              src={item.product.imgSrc}
              alt={item.product.name}
              width={80}
              height={80}/>
            <p className={styles.itemQuantity}>{item.quantity}x</p>
            <h2 className={styles.itemName}>
              {item.product.name} - {item.product.type}
              {isNotEmpty(item.attributes) &&
                <p className={styles.itemAttributes}>
                  {item.attributes.map(attribute => attribute.name).join(', ')}
                </p>}
            </h2>
            <div className={styles.itemPrice}>
              <FormattedNumber
                value={Number(item.price?.value)}
                style="currency"
                currency={item.price?.currencyCode}/>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrderItems;
