import { Cart } from 'types/cart';

export interface Price {
  value: string;
  currencyCode: string;
}

export interface OrderAttributes {
  id: number;
  name: string;
  group: {
    id: number;
    name: string;
  };
}

export interface OrderItemsType {
  id?: number;
  product: {
    id: number;
    name: string;
    type: string;
    imgSrc: string;
  };
  quantity: number;
  price: Price;
  attributes: OrderAttributes[];
}

export interface UserOrder extends Cart {
  orderNumber: string;
  orderStatus: {
    id: number;
    name: string;
  };
  note: string;
  paid: boolean;
}

export enum PaymentType {
  CreditCard = 1,
  AdvancedPayment = 2,
}
