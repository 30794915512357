import classNames from 'classnames';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'utils/string';
import styles from './availability.module.scss';

interface AvailabilityProps {
  availability: string;
  title?: string;
  isSmall?: boolean;
}

const Availability = ({ title, availability, isSmall }: AvailabilityProps): JSX.Element => {
  const { t } = useTranslation('global');
  const availabilityClasses = classNames(styles.bAvailability, {
    [styles.xSmall]: isSmall
  });

  return (
    <div
      className={availabilityClasses}
      aria-label={t('product.availability.titleAria', { value: t(`product.availability.${availability}`) })}>
      {title &&
        <h2 className='h3'>{title}</h2>}
      <em className={classNames(styles.availabilityBar, styles[`x${capitalize(availability)}`])}>
        {t(`product.availability.${availability}`)}
      </em>
    </div>
  );
};

export default Availability;
