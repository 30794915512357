import { TFunction } from 'i18next';
import {
  FormValuesAccount,
  FormValuesAddress,
  FormValuesForgotPassword,
  FormValuesLogin,
  FormValuesRegister, FormValuesResetPassword
} from 'types/forms';
import { ObjectSchema } from 'yup';
import * as yup from 'yup';
import { EMAIL_REGEX } from './regex';

export const validationLogin = (t: TFunction<'global', undefined>): ObjectSchema<FormValuesLogin> => yup.object({
  email: yup.string().matches(EMAIL_REGEX, t('errors.invalidEmail'))
    .required(t('login.emailRequired')),
  password: yup.string().required(t('login.passwordRequired'))
});

export const validationForgotPassword = (t: TFunction<'global', undefined>): ObjectSchema<FormValuesForgotPassword> => yup.object({
  email: yup.string().matches(EMAIL_REGEX, t('errors.invalidEmail'))
    .required(t('login.emailRequired'))
});

export const validationResetPassword = (t: TFunction<'global', undefined>): ObjectSchema<FormValuesResetPassword> => yup.object({
  code: yup.string().required(t('login.codeRequired')),
  password: yup.string().min(10, t('login.passwordMinLength', { min: 10 }))
    .required(t('login.passwordRequired')),
  passwordConfirmation: yup.string().when('password', {
    is: (password: string) => password && password.length > 0,
    then: (schema: yup.StringSchema) => schema.oneOf([yup.ref('password')], t('login.passwordsDoNotMatch'))
  })
});

export const validationRegister = (t: TFunction<'global', undefined>): ObjectSchema<FormValuesRegister> => yup.object({
  firstName: yup.string().required(t('login.firstNameRequired')),
  lastName: yup.string().required(t('login.lastNameRequired')),
  email: yup.string()
    .matches(EMAIL_REGEX, t('errors.invalidEmail'))
    .required(t('login.emailRequired')),
  password: yup.string()
    .min(10, t('login.passwordMinLength', { min: 10 }))
    .required(t('login.passwordRequired')),
  checkTerms: yup.boolean().oneOf([true], t('login.termsRequired'))
});

export const validationAccount = (t: TFunction<'global', undefined>): ObjectSchema<FormValuesAccount> => yup.object({
  firstName: yup.string().required(t('login.firstNameRequired')),
  lastName: yup.string().required(t('login.lastNameRequired')),
  email: yup.string()
    .matches(EMAIL_REGEX, t('errors.invalidEmail'))
    .required(t('login.emailRequired')),
  newsletterActive: yup.boolean(),
  oldPassword: yup.string(),
  password: yup.string().min(10, t('login.passwordMinLength', { min: 10 })),
  passwordConfirmation: yup.string().when('password', {
    is: (password: string) => password && password.length > 0,
    then: (schema: yup.StringSchema) => schema.oneOf([yup.ref('password')], t('login.passwordsDoNotMatch'))
  })
});

export const validationEditAddress = (t: TFunction<'global', undefined>): ObjectSchema<FormValuesAddress> => yup.object({
  id: yup.number(),
  firstName: yup.string().required(t('login.firstNameRequired')),
  lastName: yup.string().required(t('login.lastNameRequired')),
  company: yup.string(),
  street: yup.string().required(t('address.address1Required')),
  houseNumber: yup.string().required(t('address.address1NumberRequired')),
  houseNumberInfo: yup.string(),
  city: yup.string().required(t('address.cityRequired')),
  postalCode: yup.string().required(t('address.zipCodeRequired')),
  country: yup.object().shape({
    value: yup.string().required(t('address.countryRequired')),
    label: yup.string().required(t('address.countryRequired'))
  }),
  countryId: yup.string(),
  phone: yup.string().required(t('address.phoneRequired')),
  email: yup.string().matches(EMAIL_REGEX, t('errors.invalidEmail'))
    .required(t('login.emailRequired')),
  vatId: yup.string(),
  default: yup.boolean()
});
