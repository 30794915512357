import classNames from 'classnames';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './loading.module.scss';

interface LoadingProps {
  height: number;
  message?: string;
  extensionClass?: string;
}

const Loading = ({ height, message, extensionClass }: LoadingProps): JSX.Element => {
  const { t } = useTranslation('global');
  const loadingClasses = classNames(styles.bLoading, extensionClass);

  return (
    <article className={loadingClasses}>
      <svg
        className={styles.svg}
        height="793.70667"
        viewBox="0 0 1122.52 793.70667"
        width="1122.52"
        xmlns="http://www.w3.org/2000/svg"
        style={{ height: `${height}px` }}>
        <title>{t('loading')}</title>
        <clipPath id="a">
          <path d="m0 595.28h841.89v-595.28h-841.89z" />
        </clipPath>
        <g
          clipPath="url(#a)"
          transform="matrix(1.3333333 0 0 -1.3333333 0 793.70667)">
          <path
            className={styles.svgPath}
            d="m0 0c55.323 0 97.702 30.147 119.841 73.864 13.233 29.9 14.128 60.499 14.128 60.499s1.162 17.142-6.403 42.111c-2.081 6.332-4.62 12.455-7.576 18.335-8.061 13.578-21.815 27.571-33.98 42.825-7.76 9.729-14.88 19.972-19.455 30.951-19.942 47.833-10.461 81.452-10.461 81.452s-14.792-30.971-7.064-83.051c.413-2.778.93-5.429 1.519-7.984 6.541-28.212 18.968-43.617 30.365-61.759 10.346-16.463 25.801-56.995 5.473-80.942-17.806-20.972-38.948-23.83-58.334-9.107-14.481 10.995-18.115 35.714 9.015 57.927 30.291 24.802 19.428 48.46 18.299 51.39-2.797 7.278-7.686 16.738-12.534 27.028-5.641 11.972-17.528 34.106-19.73 46.208-8.876 48.782 10.863 77.925 10.863 77.925s-10.346-7.224-20.174-37.154c-9.63-29.319-3.105-57.801 2.583-76.895 2.629-8.815 7.245-21.288 9.337-34.194 4.824-29.707-16.468-34.057-24.547-34.057-7.5 0-25.925 2.219-25.925 31.472 0 9.178 2.681 20.85 6.354 31.174 7.653 21.539 13.927 40.804 14.863 74.759 1.134 41.06-22.247 76.895-22.247 76.895s20.532-48.413 9.311-88.251c-8.54-30.329-16.474-47.97-25.498-68.053-4.14-9.217-9.924-18.541-12.652-27.419-4.243-13.823-11.717-26.026 18.571-50.828 27.13-22.213 23.496-46.932 9.019-57.927-19.387-14.723-42.732-11.865-60.538 9.107-20.327 23.947-2.67 64.479 7.675 80.942 11.502 18.307 24.133 33.833 30.542 62.552.517 2.313.974 4.697 1.343 7.191 7.725 52.08-4.289 85.457-4.289 85.457s6.701-36.025-13.236-83.858c-4.13-9.902-9.825-18.645-16.154-26.901-14.36-18.74-31.984-35.004-42.049-56.736-8.639-18.665-10.797-36.931-11.259-47.296-.022-1.054-.039-2.108-.039-3.171 0-2.538.077-5.063.215-7.568 1.09-10.133 7.57-55.909 38.054-86.115 24.928-24.699 58.134-40.798 96.774-40.798"
            fill="#231f20"
            transform="translate(421.564 97.7604)"/>
        </g>
      </svg>
      {message &&
        <p className={styles.message}>{message}</p>}
    </article>
  );
};

export default Loading;
