import { useQuery } from '@tanstack/react-query';
import { GetPaymentSession } from 'types/requests';
import { fetchPaymentSession } from 'utils/fetch';
import { hasAccessToken } from 'constants/requests';

export const useGetPaymentSession = (orderId?: string): GetPaymentSession => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ['getPaymentSession', orderId],
    enabled: hasAccessToken() && !!orderId,
    queryFn: () => fetchPaymentSession(orderId)
  });

  return {
    paymentSession: data,
    isLoading,
    isError
  };
};
