import classNames from 'classnames';
import Icon from 'components/Icon/Icon';
import { JSX } from 'react';
import { FormattedDate, FormattedNumber, FormattedTime } from 'react-intl';
import { UserOrder } from 'types/order';
import Viewports from 'constants/viewports';
import MyOrderDetail from '../MyOrderDetail/MyOrderDetail';
import styles from './myOrder.module.scss';
import { useMyOrder } from './useMyOrder';

interface MyOrderProps {
  order: UserOrder;
}

const MyOrder = ({ order }: MyOrderProps): JSX.Element => {
  const { t, viewport, isDetailVisible, orderDate, myOrderProps, handleDetailVisibility } = useMyOrder(order);

  const orderStatusClasses = classNames(styles.text, styles.xStatus, {
    [styles.xCancelled]: order.orderStatus.id === 4,
    [styles.xCompleted]: order.orderStatus.id === 3,
    [styles.xProcessing]: order.orderStatus.id === 2,
    [styles.xPending]: order.orderStatus.id === 1
  });
  const triggerDetailButtonClasses = classNames(styles.triggerDetailButton, {
    [styles.xIsDetailVisible]: isDetailVisible
  });

  return (
    <article key={order.id} className={styles.bMyOrder} {...myOrderProps}>
      <ul className={styles.myOrderList}>
        <li className={styles.myOrderListItem}>
          <h2 className={styles.title}>{t('orders.date')}</h2>
          <time className={styles.text} dateTime={order.dateTime}>
            <FormattedDate
              value={orderDate}
              year='numeric'
              month='short'
              day='2-digit'/>,
            <FormattedTime
              value={orderDate}
              timeStyle='short'/>
          </time>
        </li>
        <li className={styles.myOrderListItem}>
          <h2 className={styles.title}>{t('orders.orderNumberTitle')}</h2>
          <p className={styles.text}>{t('orders.orderNumber', { number: order.orderNumber })}</p>
        </li>
        <li className={styles.myOrderListItem}>
          <h2 className={styles.title}>{t('orders.orderStatus')}</h2>
          <p className={orderStatusClasses} data-status={order.orderStatus.id}>{order.orderStatus.name}</p>
        </li>
        <li className={styles.myOrderListItem}>
          <h2 className={styles.title}>{t('orders.orderTotal')}</h2>
          <p className={styles.text}>
            <FormattedNumber
              value={Number(order.total.value)}
              style='currency'
              currency={order.total.currencyCode}/></p>
        </li>
        {viewport !== Viewports.MOBILE &&
          <li className={styles.myOrderListItem}>
            <button
              type='button'
              className={triggerDetailButtonClasses}
              onClick={handleDetailVisibility}>
              <Icon
                iconName='chevron-right'
                iconTitle={t('orders.viewOrder')}/>
            </button>
          </li>}
      </ul>
      {isDetailVisible &&
        <MyOrderDetail order={order}/>}
    </article>
  );
};

export default MyOrder;
