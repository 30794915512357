import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { Dispatch } from 'react';
import { queryMutation } from 'services/services';
import { GenericMutationResponse } from 'types/requests';

interface UseForgotReturn extends GenericMutationResponse<string> {
  forgotPassword: UseMutateFunction<Response, Error, string, unknown>;
}

export const useForgot = (setIsSuccessRequest: Dispatch<boolean>): UseForgotReturn  => {
  const { mutate, ...args } = useMutation({
    mutationFn: (email: string) => {
      return queryMutation('user/forgot-password?', 'POST', { email });
    },
    onSuccess: (data) => {
      if (data.status === 200) {
        setIsSuccessRequest(true);
      }

      return data;
    }
  });

  return {
    forgotPassword: mutate,
    ...args
  };
};
