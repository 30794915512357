import { ErrorLayout } from 'components/ErrorPage/ErrorPage';
import { JSX } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { Outlet } from 'react-router-dom';
import VIEWPORTS from 'constants/viewports';
import Navigation from '../_navi/Navigation/Navigation';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Toast from '../Toast/Toast';
import styles from './layout.module.scss';
import { useLayout } from './useLayout';

interface LayoutProps {
  ErrorElement?: JSX.Element;
}

const Layout = ({ ErrorElement }: LayoutProps): JSX.Element => {
  const {
    i18n,
    pageTitle,
    viewport,
    categories } = useLayout();
  
  return (
    <IntlProvider locale={i18n.language}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ErrorBoundary fallback={<ErrorLayout />}>
        <Header categories={categories} />
        {viewport === VIEWPORTS.DESKTOP &&
          <Navigation categories={categories} />}
      </ErrorBoundary>
      <main className={styles.bLayout}>
        <Outlet />
        {ErrorElement}
        <Toast />
      </main>
      <ErrorBoundary fallback={<ErrorLayout />}>
        <Footer categories={categories} />
      </ErrorBoundary>
    </IntlProvider>
  );
};

export default Layout;
