import { FormikHelpers } from 'formik';
import { TFunction } from 'i18next';
import { useAtom, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAddresses } from 'services/getAddresses/getAddresses';
import { useGetCountries } from 'services/getCountries/getCountries';
import { useAddAddress } from 'services/updateAddresses/addAddresses';
import { useUpdateAddress } from 'services/updateAddresses/updateAddress';
import { modalAtom, pageTitleAtom } from 'store/store';
import { FormValuesAddress } from 'types/forms';
import { SelectOption } from 'types/sorting';
import { getCountriesOptions } from 'utils/countries';
import { objectIsNotEmpty } from 'utils/validation';
import { ROUTE_PATHS } from 'constants/paths';

interface UseEditAddressReturn {
  t: TFunction<'global', undefined>;
  pageTitle: string;
  isNewAddress: boolean;
  initialValues: FormValuesAddress;
  isLoadingCountries?: boolean;
  handleSubmit: (values: FormValuesAddress, { setSubmitting }: FormikHelpers<FormValuesAddress>) => void;
  navigateToAddresses(): void;
  handleCountryChange(selectedCountry?: SelectOption | unknown, setFieldValue?: (field: string, selected: SelectOption) => void): void;
  cancelModal(): void;
  defaultCountryOption?: SelectOption;
  countriesOptions?: SelectOption[];
}

export const useEditAddress = (isCheckout?: boolean): UseEditAddressReturn => {
  const { t } = useTranslation('global');
  const { addressIdParam } = useParams<{ addressIdParam: string }>();
  const isNewAddress = addressIdParam === 'new';
  const navigate = useNavigate();
  const [pageTitle, setPageTitle] = useAtom(pageTitleAtom);

  useEffect(() => {
    if (!isCheckout) {
      if (isNewAddress) {
        setPageTitle(t('address.addAddress'));
      } else {
        setPageTitle(t('address.editAddress'));
      }
    }
  }, [isCheckout, isNewAddress, setPageTitle, t]);

  const setModalOpenName = useSetAtom(modalAtom);
  const { addresses } = useGetAddresses(isNewAddress);
  const { updateAddress } = useUpdateAddress();
  const { addAddresses } = useAddAddress();
  const { countries, isLoading } = useGetCountries();

  const [defaultCountryOption, setDefaultCountryOption] = useState<SelectOption>();
  const [initialValues, setInitialValues] = useState<FormValuesAddress>({
    firstName: '',
    lastName: '',
    company: '',
    street: '',
    houseNumber: '',
    houseNumberInfo: '',
    city: '',
    postalCode: '',
    country: {} as SelectOption,
    phone: '',
    email: '',
    vatId: '',
    default: false
  });

  const currentAddress = addresses?.find(address => address.id?.toString() === addressIdParam);

  useEffect(() => {
    if (objectIsNotEmpty(currentAddress)) {
      setInitialValues({
        ...currentAddress,
        firstName: currentAddress?.firstName || '',
        lastName: currentAddress?.lastName || '',
        street: currentAddress?.street || '',
        houseNumber: currentAddress?.houseNumber || '',
        houseNumberInfo: currentAddress?.houseNumberInfo || '',
        city: currentAddress?.city || '',
        postalCode: currentAddress?.postalCode || '',
        email: currentAddress?.email || '',
        country: currentAddress?.country ? getCountriesOptions([currentAddress.country])[0] : {} as SelectOption
      });
    }
  }, [currentAddress]);

  useEffect(() => {
    if (objectIsNotEmpty(initialValues.country)) {
      setDefaultCountryOption(initialValues?.country);
    }
  }, [initialValues.country]);

  const navigateToAddresses = (): void => navigate(ROUTE_PATHS.addresses);

  const handleSubmit = (values: FormValuesAddress, { setSubmitting }: FormikHelpers<FormValuesAddress>): void => {
    if (!isNewAddress && objectIsNotEmpty(currentAddress)) {
      updateAddress({
        ...values,
        ...currentAddress?.id && { id: currentAddress?.id },
        ...values.country.value && { countryId: values.country.value }
      });
    } else if ((isNewAddress || isCheckout) && objectIsNotEmpty(values)) {
      addAddresses({
        ...values,
        ...values.country.value && { countryId: values.country.value },
        default: true
      });
    }
    setSubmitting(false);
    if (isCheckout) {
      cancelModal();
    } else {
      navigateToAddresses();
    }
  };

  const handleCountryChange = (selectedCountry?: SelectOption | unknown, setFieldValue?: (field: string, selected: SelectOption) => void): void => {
    if (selectedCountry && setFieldValue) {
      setFieldValue('country', selectedCountry as SelectOption);
    }
  };

  const cancelModal = (): void => {
    setModalOpenName(RESET);
  };

  return {
    t,
    isNewAddress,
    pageTitle,
    initialValues,
    isLoadingCountries: isLoading,
    defaultCountryOption,
    countriesOptions: countries && getCountriesOptions(countries),
    navigateToAddresses,
    handleSubmit,
    handleCountryChange,
    cancelModal
  };
};
