import { TFunction } from 'i18next';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { stripeSecretAtom } from 'store/store';
import { useLocationParams } from 'utils/navigation';
import { isEmpty, isNotEmpty } from 'utils/validation';
import { ROUTE_PATHS } from 'constants/paths';
import { hasAccessToken } from 'constants/requests';
import type { Stripe } from '@stripe/stripe-js';

interface UseStripePaymentReturn {
  t: TFunction<'global', undefined>;
  options: { clientSecret: string };
  stripePromise: Stripe | null;
}

export const useCheckoutPayment = (): UseStripePaymentReturn => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const stripeSecret = useAtomValue(stripeSecretAtom);
  const orderIdParam = useLocationParams('orderId');

  useEffect(() => {
    if (!hasAccessToken()) {
      navigate(`/${ROUTE_PATHS.login}`);
      return;
    }
  }, [navigate]);

  useEffect(() => {
    if (isEmpty(stripeSecret)) {
      navigate(`${ROUTE_PATHS.myOrders}?order=${orderIdParam}&error=no-stripe-order`);
      return;
    } else if (isEmpty(orderIdParam)) {
      navigate(`${ROUTE_PATHS.myOrders}?error=no-order`);
      return;
    }
  }, [navigate, orderIdParam, stripeSecret]);

  const options = { clientSecret: stripeSecret };

  useEffect(() => {
    if (isNotEmpty(options.clientSecret)) {
      import('@stripe/stripe-js').then(({ loadStripe }) => {
        loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY).then((stripe) => {
          setStripePromise(stripe);
        });
      });
    }
  }, [options.clientSecret]);

  return {
    t,
    stripePromise,
    options
  };
};
