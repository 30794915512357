import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { queryMutation } from 'services/services';
import { cartIdAtom } from 'store/store';
import { CartItemType } from 'types/cart';
import { GenericMutationResponse } from 'types/requests';

interface UseUpdateCartItemReturn extends GenericMutationResponse<CartItemType> {
  updateCartItem: UseMutateFunction<Response, Error, CartItemType, unknown>;
}

export const useUpdateCartItem = (): UseUpdateCartItemReturn => {
  const cartId = useAtomValue(cartIdAtom);
  const queryClient = useQueryClient();

  const { mutate, ...args } = useMutation({
    mutationFn: (cartItem: CartItemType) => {
      if (cartItem.quantity === 0) {
        return queryMutation(`shopping-cart/${cartId}/items/${cartItem.id}?`, 'DELETE');
      }

      return queryMutation(`shopping-cart/${cartId}/items/${cartItem.id}?`, 'PATCH', cartItem);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getCart', cartId] });
    }
  });

  return {
    updateCartItem: mutate,
    ...args
  };
};
