import { useSetAtom } from 'jotai';
import { useResetAtom, RESET } from 'jotai/utils';
import { JSX, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { cartIdAtom, cartItemsQuantityAtom, pageTitleAtom, previousUrlAtom, userAtom } from 'store/store';
import { resetAccessToken } from 'constants/requests';

const Logout = (): JSX.Element => {
  const resetPreviousUrl = useResetAtom(previousUrlAtom);
  const resetPageTitle = useResetAtom(pageTitleAtom);
  const setUser = useSetAtom(userAtom);
  const setCartId = useSetAtom(cartIdAtom);
  const setCartItemsQuantity = useSetAtom(cartItemsQuantityAtom);

  resetAccessToken();

  useEffect(() => {
    setUser(RESET);
  }, [setUser]);

  useEffect(() => {
    setCartId(RESET);
  }, [setCartId]);

  useEffect(() => {
    setCartItemsQuantity(RESET);
  }, [setCartItemsQuantity]);

  useEffect(() => {
    resetPreviousUrl();
  }, [resetPreviousUrl]);

  useEffect(() => {
    resetPageTitle();
  }, [resetPageTitle]);

  return <Navigate to='/' />;
};

export default Logout;
