import { useQuery } from '@tanstack/react-query';
import { GetProduct } from 'types/requests';
import { fetchProduct } from 'utils/fetch';
import { isNotEmpty } from 'utils/validation';

export const useGetProduct = (id?: string): GetProduct => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ['getProduct', id],
    queryFn: () => fetchProduct(id),
    enabled: isNotEmpty(id)
  });

  return {
    product: data,
    isLoading,
    isError
  };
};
