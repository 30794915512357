import classNames from 'classnames';
import Button from 'components/Button/Button';
import FlashMessage from 'components/FlashMessage/FlashMessage';
import Icon from 'components/Icon/Icon';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { JSX } from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from 'constants/paths';
import { validationForgotPassword } from 'constants/validation';
import styles from './forgotPassword.module.scss';
import { useForgotPassword } from './useForgotPassword';

const ForgotPassword = (): JSX.Element => {
  const {
    t,
    initialValues,
    isSuccessRequest,
    setFieldErrorRef,
    handleSubmit
  } = useForgotPassword();

  return (
    <section className={classNames(styles.bForgotPassword, 'formCenter')}>
      <h1 className='h2'>{t('login.forgotPassword')}</h1>
      <p className={styles.description}>{t('login.forgotPasswordText')}</p>
      {isSuccessRequest &&
        <FlashMessage
          message={t('login.forgotPasswordSuccess')}
          type='success'/>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationForgotPassword(t)}
        onSubmit={handleSubmit}
        validateOnMount={true}>
        {({ isSubmitting, isValid, setFieldError }) => {
          setFieldErrorRef.current = setFieldError;

          return (
            <Form className='form'>
              <Field
                autoComplete='email'
                type='email'
                name='email'
                className='input'
                id='email'
                placeholder={t('login.email')}/>
              <ErrorMessage
                name='email'
                component='p'
                className='inlineError'/>

              <Link to={`/${ROUTE_PATHS.login}`} className={styles.loginLink}>
                <Icon iconName='chevron-left' />
                <span className={styles.loginLinkText}>
                  {t('login.title')}
                </span>
              </Link>

              <Button
                type='submit'
                disabled={isSubmitting || !isValid || isSuccessRequest}
                extensionClass='submitButton'>
                {t('login.submit')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default ForgotPassword;
