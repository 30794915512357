import classNames from 'classnames';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { NavigationItem } from 'types/navigation';
import { isNotUndefined } from 'utils/validation';
import { NAVIGATION_PAGES } from 'constants/navigation';
import certificate from '../../assets/images/SSLsecure.png';
import styles from './footer.module.scss';

interface FooterProps {
  categories?: NavigationItem[];
}

const Footer = ({ categories }: FooterProps): JSX.Element => {
  const { t } = useTranslation('global');
  const { i18n } = useTranslation();
  const footerContactItemClasses = classNames(styles.navItem, styles.xContactColumn);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <footer className={styles.bFooter}>
      <ul className={styles.navList}>
        {isNotUndefined(categories) && categories.map(item => (
          <li key={item.id} className={styles.navItem}>
            <Link to={item.slug} className={styles.navLink}>{t(item.name)}</Link>
            {item.children &&
              <ul className={styles.navSubList}>
                {item.children && item.children.map(subItem => (
                  <li key={subItem.id} className={styles.subListItem}>
                    <Link to={subItem.slug} className={styles.subListLink}>{t(subItem.name)}</Link>
                  </li>
                ))}
              </ul>}
          </li>
        ))}
        <li className={footerContactItemClasses}>
          {NAVIGATION_PAGES(t, i18n.language).map(item => (
            <Link key={item.id} to={item.slug} className={styles.navLink}>{item.name}</Link>))}
          <address className={styles.contact}>
            <span className={styles.contactItem}>store@siddharta.net</span>
            <span className={styles.contactItem}>{t('footer.phoneNumber')}</span>
          </address>
        </li>
        <li className={footerContactItemClasses}>
          <dl className={styles.paymentList}>
            <dt className={styles.paymentTerm}>{t('footer.payments.method')}</dt>
            <dd className={styles.paymentDescription}>MasterCard</dd>
            <dd className={styles.paymentDescription}>Maestro</dd>
            <dd className={styles.paymentDescription}>Visa</dd>
            <dd className={styles.paymentDescription}>American Express</dd>
            <dd className={styles.paymentDescription}>Diners Club</dd>
            <dd className={styles.paymentDescription}>{t('footer.payments.methodAdvanced')}</dd>
          </dl>
          <img
            className={styles.paymentSSL}
            src={certificate}
            alt={t('footer.ssl')}
            width='120'/>
        </li>
      </ul>
      <p className={styles.copyright}>
        <span className={styles.copyrightItem}>
          Powered by <Link to='https://www.siddharta.net/en/contact' className={styles.copyrightLink}>Siddharta Web Team</Link> from Berlin with &#9829;
        </span>
        <span className={styles.copyrightItem}>
          ©{currentYear} Siddharta. Vse pravice pridržane. All Rights Reserved.
        </span>
      </p>
    </footer>
  );
};

export default Footer;
