import classNames from 'classnames';
import { TFunction } from 'i18next';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { NAVIGATION_LANGUAGE } from 'constants/navigation';
import styles from './languagePicker.module.scss';

interface LanguagePickerProps {
  extensionClass?: string;
}

const LanguagePicker = ({ extensionClass }: LanguagePickerProps): JSX.Element => {
  const [t, i18n] = useTranslation('global');

  const handleLanguageClick = (language: string) => (): Promise<TFunction<'global', undefined>> => (
    i18n.changeLanguage(language)
  );

  const languagePickerClasses = classNames(styles.bLanguagePicker, extensionClass);
  const languageButtonClasses = (language: string): string => classNames(styles.btnLink, {
    [styles.xActive]: i18n.language === language
  });

  return (
    <ul className={languagePickerClasses}>
      {NAVIGATION_LANGUAGE.map(item => (
        <li key={item.id} className={styles.listItem}>
          <button
            className={languageButtonClasses(item.language)}
            onClick={handleLanguageClick(item.language)}>
            {t(item.name)}
          </button>
        </li>))}
    </ul>
  );
};

export default LanguagePicker;
