import { TFunction } from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'constants/paths';
import { hasAccessToken } from 'constants/requests';

interface UseMyAccountReturn {
  t: TFunction<'global', undefined>;
}

export const useMyAccount = (): UseMyAccountReturn => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasAccessToken()) {
      navigate(`/${ROUTE_PATHS.login}`);

      return;
    }
  }, [navigate]);

  return {
    t
  };
};
