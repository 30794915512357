import { useQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { cartIdAtom } from 'store/store';
import { GetCart } from 'types/requests';
import { fetchCart } from 'utils/fetch';
import { isNotEmpty } from 'utils/validation';
import { ROUTE_PATHS } from 'constants/paths';
import { resetAccessToken } from 'constants/requests';

export const useGetCart = (id?: string, isConfirmation?: boolean): GetCart => {
  const { t } = useTranslation('global');
  const setCartID = useSetAtom(cartIdAtom);
  const navigate = useNavigate();

  const { data, isError, isSuccess, isLoading } = useQuery({
    queryKey: ['getCart', id],
    queryFn: () => fetchCart(id),
    enabled: isNotEmpty(id) && !isConfirmation,
    refetchOnMount: true
  });

  useEffect(() => {
    if (isError) {
      setCartID(RESET);
      resetAccessToken();

      toast(t('errors.cartExpired'));
      navigate(`/${ROUTE_PATHS.cart}?error=expired-cart`);
    }
  }, [isError, navigate, setCartID, t]);

  return {
    cart: data,
    isLoadingCart: isLoading,
    isSuccess,
    isError
  };
};
