import iconFacebook from 'assets/images/facebook.svg';
import iconGoogle from 'assets/images/google.svg';
import classNames from 'classnames';
import FlashMessage from 'components/FlashMessage/FlashMessage';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { JSX } from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from 'constants/paths';
import { validationLogin } from 'constants/validation';
import Button from '../../Button/Button';
import styles from './login.module.scss';
import { useLogin } from './useLogin';

const Login = (): JSX.Element => {
  const {
    t,
    socialLoginLinkGoogle,
    socialLoginLinkFacebook,
    isSuccessEmail,
    tokenErrorMsg,
    initialValues,
    handleSubmit
  } = useLogin();

  return (
    <section className={classNames(styles.bLogin, 'formCenter')}>
      <h1 className='h2'>{t('login.title')}</h1>
      {isSuccessEmail &&
        <FlashMessage
          message={t('login.textEmailConfirmed')}
          type='success'/>}
      {!isSuccessEmail && tokenErrorMsg &&
        <FlashMessage
          message={t(tokenErrorMsg)}
          type='error'/>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationLogin(t)}
        onSubmit={handleSubmit}
        validateOnMount={true}
        validateOnBlur={false}>
        {({ isSubmitting, isValid }) => (
          <Form className='form'>
            <Field
              autoComplete='email'
              type='email'
              name='email'
              className='input'
              id='email'
              placeholder={t('login.email')}/>
            <ErrorMessage
              name='email'
              component='p'
              className='inlineError'/>

            <Field
              autoComplete='off'
              name='password'
              className='input'
              id='password'
              placeholder={t('login.password')}
              type='password'/>
            <ErrorMessage
              name='password'
              component='p'
              className='inlineError'/>

            <Link to={`/${ROUTE_PATHS.forgotPassword}`} className={styles.forgotPasswordLink}>
              {t('login.forgotPassword')}
            </Link>

            <Button
              type='submit'
              disabled={isSubmitting || !isValid}
              extensionClass='submitButton'>
              {t('login.title')}
            </Button>

            <Link to={`/${ROUTE_PATHS.register}`} className='nextToSubmitLink'>
              {t('login.register')}
            </Link>

            <div className={styles.socialLoginWrapper} data-social={t('login.or')}>
              <Link to={socialLoginLinkGoogle}
                className={styles.loginWithSocial}
                aria-label={t('login.google')}>
                <img src={iconGoogle}
                  className={styles.iconGoogle}
                  alt={t('login.google')}
                  height='24'
                  width='24'/>
                <span className={styles.text}>{t('login.google')}</span>
              </Link>
              <Link to={socialLoginLinkFacebook}
                className={styles.loginWithSocial}
                aria-label={t('login.facebook')}>
                <img src={iconFacebook}
                  className={styles.iconFacebook}
                  alt={t('login.facebook')}
                  height='24'
                  width='24'/>
                <span className={styles.text}>{t('login.facebook')}</span>
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default Login;
