import { JSX } from 'react';
import { Link } from 'react-router-dom';
import { NavigationItem } from 'types/navigation';
import { useViewport } from 'utils/useViewport';
import { isEmpty } from 'utils/validation';
import { SORTING_OPTIONS } from 'constants/sorting';
import VIEWPORTS from 'constants/viewports';
import Listing from '../../Listing/Listing';
import Loading from '../../Loading/Loading';
import SelectBox from '../../SelectBox/SelectBox';
import styles from './listingOverview.module.scss';
import { useListingOverview } from './useListingOverview';

const ListingOverview = (): JSX.Element => {
  const {
    t,
    title,
    products,
    isLoading,
    isError,
    subCategories,
    selectedSortOption,
    getLastTwoPartsOfSlug,
    handleSortChange } = useListingOverview();
  const viewport = useViewport();

  return (
    <section className={styles.bListingOverview}>
      <h1 className='h2'>{title}</h1>
      <aside className={styles.filterBar}>
        {products &&
          <>
            {viewport === VIEWPORTS.DESKTOP && subCategories &&
              <dl className={styles.subCategoryList}>
                {subCategories.map((subCategory: NavigationItem) => (
                  <dd key={subCategory.name} className={styles.subCategoryDesc}>
                    <Link to={getLastTwoPartsOfSlug(subCategory.slug)}>
                      {subCategory.name}
                    </Link>
                  </dd>))}
              </dl>}
            <span className={styles.productCount}>
              {t('product.listQuantity', { number: products.length })}
            </span>
            <SelectBox
              id='sort-select'
              options={SORTING_OPTIONS}
              defaultValue={SORTING_OPTIONS[0]}
              selectedOption={selectedSortOption}
              classes={styles.sortSelect}
              onChange={handleSortChange}
              placeholder={t('sorting.sortSelect')}/>
          </>}
      </aside>
      {isError &&
        <p>{t('errors.noProducts')}</p>}
      {isLoading || !isError && isEmpty(products) ?
        <Loading height={70} /> :
        <Listing
          listItems={products}/>}
    </section>
  );
};

export default ListingOverview;
