import { JSX } from 'react';
import CheckoutSummary from '../CheckoutSummary/CheckoutSummary';
import { useCheckoutConfirm } from './useCheckoutConfirm';

const CheckoutConfirm = (): JSX.Element => {
  const { order, isLoadingOrder, isAdvancedPaymentOrder } = useCheckoutConfirm();

  return (
    <CheckoutSummary
      isLoadingOrder={isLoadingOrder}
      isConfirmation={true}
      isAdvancedPaymentOrder={isAdvancedPaymentOrder}
      order={order}/>
  );
};

export default CheckoutConfirm;
