import { ErrorLayout } from 'components/ErrorPage/ErrorPage';
import { useAtomValue } from 'jotai';
import { JSX } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import { pageTitleAtom } from 'store/store';
import { useViewport } from 'utils/useViewport';
import VIEWPORTS from 'constants/viewports';
import Sidebar from '../Sidebar/Sidebar';
import styles from './myAccount.module.scss';
import { useMyAccount } from './useMyAccount';

const MyAccount = (): JSX.Element => {
  const { t } = useMyAccount();
  const viewport = useViewport();
  const pageTitle = useAtomValue(pageTitleAtom);

  return (
    <section className={styles.bMyAccount}>
      <h1 className='h2'>{t('login.myAccount')} {pageTitle}</h1>
      <div className={styles.wrapper}>
        {viewport === VIEWPORTS.DESKTOP &&
          <Sidebar/>}
        <article className={styles.content}>
          <ErrorBoundary fallback={<ErrorLayout />}>
            <Outlet />
          </ErrorBoundary>
        </article>
      </div>
    </section>
  );
};

export default MyAccount;
