import classNames from 'classnames';
import Icon from 'components/Icon/Icon';
import SelectBox from 'components/SelectBox/SelectBox';
import { JSX } from 'react';
import { FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import { CartItemType } from 'types/cart';
import { URL_PRODUCT_PREFIX } from 'constants/paths';
import { QUANTITY_OPTIONS } from 'constants/sorting';
import styles from './cartItem.module.scss';
import { useCartItem } from './useCartItem';

interface CartItemProps {
  item: CartItemType;
  isEditable?: boolean;
  isDisabled?: boolean;
}

const CartItem = ({ item, isEditable, isDisabled }: CartItemProps): JSX.Element => {
  const {
    t,
    isPending,
    quantityOption,
    handleQuantityChange,
    productColor,
    productSize,
    handleRemoveClick
  } = useCartItem(item);

  const cartItemClasses = classNames(styles.bCartItem, {
    [styles.xDisabled]: isDisabled
  });

  return (
    <li className={cartItemClasses}>
      <div className={styles.infoWrapper}>
        {!isDisabled &&
          <Link to={`/${URL_PRODUCT_PREFIX}/${item.product.id}-${item.product.slug || item.product.name}`}>
            <img
              className={styles.itemImg}
              src={item.product.imgSrc}
              alt={item.product.name}/>
          </Link>}
        <div className={styles.itemInfo}>
          <h2 className={styles.itemTitle}>
            <Link to={`/${URL_PRODUCT_PREFIX}/${item.product.id}-${item.product.slug || item.product.name}`}
              className={styles.itemTitleLink}>
              {item.product.name}
            </Link>
          </h2>
          <p className={styles.itemAttributes}>
            {item.product.type}
          </p>
          {productSize &&
          <p className={styles.itemAttributes}>
            {t('cart.size', { size: productSize })}
          </p>}
          {productColor &&
            <p className={styles.itemAttributes}>
              {t('cart.color', { color: productColor })}
            </p>}
          {isDisabled &&
            <p className={styles.itemAttributes}>
              {t('product.availability.out_of_stock')}
            </p>}
        </div>
      </div>
      {isEditable &&
        <div className={styles.actions}>
          <SelectBox
            id='quantity-select'
            options={QUANTITY_OPTIONS()}
            classes={styles.selectWrapper}
            placeholder={t('product.quantitySelect')}
            selectedOption={quantityOption}
            disabled={isDisabled || isPending}
            onChange={handleQuantityChange}/>
          <button
            className={styles.removeBtn}
            type="button"
            disabled={isDisabled || isPending}
            onClick={(): void => handleRemoveClick()}>
            <Icon iconName="delete" iconTitle={t('cart.removeItem', { name: item.product.name })}/>
          </button>
        </div>}
      <p className={styles.itemPrice}>
        <FormattedNumber
          value={Number(item.price.value)}
          style='currency'
          currency={item.price.currencyCode}/>
      </p>
    </li>
  );
};

export default CartItem;
