import classNames from 'classnames';
import Button from 'components/Button/Button';
import FlashMessage from 'components/FlashMessage/FlashMessage';
import Loading from 'components/Loading/Loading';
import OrderItems from 'components/OrderItems/OrderItems';
import PricesList from 'components/PricesList/PricesList';
import { JSX } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input/min';
import { UserOrder } from 'types/order';
import styles from './checkoutSummary.module.scss';
import { useCheckoutSummary } from './useCheckoutSummary';

interface CheckoutSummaryProps {
  isConfirmation?: boolean;
  isLoadingOrder?: boolean;
  isAdvancedPaymentOrder?: boolean;
  order?: UserOrder;
}

const CheckoutSummary = ({ isConfirmation, isLoadingOrder, order, isAdvancedPaymentOrder }: CheckoutSummaryProps): JSX.Element => {
  const {
    t,
    cart,
    checkoutItems,
    isLoadingCart,
    isDisabledBuyButton,
    handleCheckoutClick,
    handleContinueShopping,
    handleBuyClick
  } = useCheckoutSummary(isConfirmation);

  const orderItems = checkoutItems || order?.items;
  const cartOrOrder = cart || order;
  const isLoading = isLoadingCart || isLoadingOrder;

  return (
    <article className={styles.bCheckoutSummary}>
      <h1 className="h2">{isConfirmation ? t('orders.orderSuccessTitle') : t('checkout.summary')}</h1>
      {isConfirmation &&
        <FlashMessage
          extensionClass={styles.flashMessage}
          message={t('orders.orderSuccess')}
          details={isAdvancedPaymentOrder ? t('orders.orderSuccessAdvancedDetail') : undefined}
          type='success'/>}

      {isLoading && <Loading height={100}/>}
      {!isLoading &&
        <>
          <section className={styles.clientData}>
            <section className={styles.checkoutSection}>
              <h2 className="h3">{t('checkout.shipping')}</h2>
              <address className={styles.description}>
                {cartOrOrder?.deliveryAddress.firstName} {cartOrOrder?.deliveryAddress.lastName}<br/>
                {cartOrOrder?.deliveryAddress.company}
                {cartOrOrder?.deliveryAddress.company && <br/>}
                {cartOrOrder?.deliveryAddress.street} {cartOrOrder?.deliveryAddress.houseNumber}
                {cartOrOrder?.deliveryAddress.houseNumberInfo && <br/>}
                {cartOrOrder?.deliveryAddress.houseNumberInfo}<br/>
                {cartOrOrder?.deliveryAddress.postalCode} {cartOrOrder?.deliveryAddress.city}<br/>
                {cartOrOrder?.deliveryAddress.country?.name}<br/>
                {cartOrOrder?.deliveryAddress.phone && formatPhoneNumberIntl(cartOrOrder?.deliveryAddress.phone)}
              </address>
            </section>
            <section className={styles.checkoutSection}>
              <h2 className="h3">{t('orders.billingAddress')}</h2>
              {cartOrOrder?.deliveryAddress.id === cartOrOrder?.paymentAddress.id ?
                <p className={styles.description}>{t('checkout.sameAsShipping')}</p> :
                <address className={styles.description}>
                  {cartOrOrder?.paymentAddress.firstName} {cartOrOrder?.paymentAddress.lastName}<br/>
                  {cartOrOrder?.paymentAddress.company}
                  {cartOrOrder?.paymentAddress.company && <br/>}
                  {cartOrOrder?.paymentAddress.street} {cartOrOrder?.paymentAddress.houseNumber}
                  {cartOrOrder?.paymentAddress.houseNumberInfo && <br/>}
                  {cartOrOrder?.paymentAddress.houseNumberInfo}<br/>
                  {cartOrOrder?.paymentAddress.postalCode} {cartOrOrder?.paymentAddress.city}<br/>
                  {cartOrOrder?.paymentAddress.country?.name}<br/>
                  {cartOrOrder?.paymentAddress.phone && formatPhoneNumberIntl(cartOrOrder?.paymentAddress.phone)}
                </address>}
            </section>
            <section className={styles.checkoutSection}>
              <h2 className="h3">{t('orders.shippingMethod')}</h2>
              <p className={styles.description}>{cartOrOrder?.deliveryType.name}</p>
            </section>
            <section className={styles.checkoutSection}>
              <h2 className="h3">{t('orders.paymentMethod')}</h2>
              <p className={styles.description}>{cartOrOrder?.paymentType.name}</p>
            </section>
          </section>
          <section className={styles.cartItems}>
            <h2 className="h3">{t('orders.itemsOrdered')}</h2>
            {isLoading && !orderItems ?
              <Loading height={70}/> :
              <>
                {orderItems &&
                  <OrderItems orderItems={orderItems} isCheckout/>}
                <PricesList
                  amountWithoutTax={cartOrOrder?.amountWithoutTax}
                  amount={cartOrOrder?.amount}
                  deliveryCosts={cartOrOrder?.deliveryCosts}
                  total={cartOrOrder?.total}/>
              </>}
          </section>
        </>}
      <div className={classNames(styles.buttonsWrapper, { [styles.xConfirmation]: isConfirmation })}>
        {!isConfirmation &&
        <>
          <Button
            onClick={handleCheckoutClick}
            extensionClass='xButtonCheckout'
            disabled={isLoading}
            icon='chevron-left'>
            {t('checkout.title')}
          </Button>
          <Button
            onClick={handleBuyClick}
            extensionClass='xButtonBuy'
            disabled={isDisabledBuyButton}>
            {t('checkout.buyNow')}
          </Button>
        </>}
        {isConfirmation &&
          <Button
            onClick={handleContinueShopping}
            extensionClass='xButtonShopping'
            disabled={isLoading}>
            {t('cart.emptyContinue')}
          </Button>
        }
      </div>
    </article>
  );
};

export default CheckoutSummary;
