import { useQuery } from '@tanstack/react-query';
import { GetCountries } from 'types/requests';
import { fetchCountries } from 'utils/fetch';

export const useGetCountries = (): GetCountries => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ['getCountries'],
    queryFn: () => fetchCountries(),
    staleTime: Infinity
  });

  return {
    countries: data,
    isLoading,
    isError
  };
};
