import { TFunction } from 'i18next';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetFeatured } from 'services/getFeatured/getFeatured';
import { useGetProducts } from 'services/getProducts/getProducts';
import { pageTitleAtom } from 'store/store';
import { FeaturedProduct, ListProduct } from 'types/products';
import { SORT_VALUES } from 'constants/sorting';

interface UseStoreOverviewReturn {
  t: TFunction<'global', undefined>;
  isLoading?: boolean;
  products?: ListProduct[];
  featuredProduct?: FeaturedProduct;
}

export const useStoreOverview = (): UseStoreOverviewReturn => {
  const { t } = useTranslation('global');
  const { featuredProduct } = useGetFeatured();
  const { products, isLoading } = useGetProducts({ createdAt: SORT_VALUES.DESC });

  const setPageTitle = useSetAtom(pageTitleAtom);
  const metaTitle = `${t('navigation.store')} | ${t('pageTitle')} ${t('pageTitleSub')}`;

  useEffect(() => {
    setPageTitle(metaTitle);
  }, [metaTitle, setPageTitle]);
  
  return {
    t,
    isLoading,
    products,
    featuredProduct
  };
};
