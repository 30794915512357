import { JSX } from 'react';
import { Link } from 'react-router-dom';
import { NavigationItem } from 'types/navigation';
import { addActiveClass } from 'utils/navigation';
import { isNotUndefined } from 'utils/validation';
import NavItemStore from '../NavItemStore/NavItemStore';
import styles from './navigation.module.scss';
import { useNavigation } from './useNavigation';

interface NavigationProps {
  categories?: NavigationItem[];
}

const Navigation = ({ categories }: NavigationProps): JSX.Element => {
  const {
    t,
    location
  } = useNavigation();

  return (
    <nav className={styles.bNavigation} role='navigation'>
      {isNotUndefined(categories) &&
        <ul className={styles.navList}>
          <NavItemStore navButtonClasses={slug => addActiveClass(slug, location, styles.navLink)}/>
          {categories.map(item => (
            <li key={item.id} className={styles.navItem}>
              <Link to={item.slug} className={addActiveClass(item.slug, location, styles.navLink)}>{t(item.name)}</Link>
            </li>
          ))}
        </ul>}
    </nav>
  );
};

export default Navigation;
