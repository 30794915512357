import { MutableRefObject, useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import { Product } from 'types/products';
import { Viewport } from 'types/viewports';
import { useViewport } from 'utils/useViewport';

interface UseGalleryReturn {
  mainSliderRef:  MutableRefObject<Slider | null>;
  thumbsSliderRef: MutableRefObject<Slider | null>;
  mainSliderSettings: Settings;
  thumbsSliderSettings: Settings;
  hasMultipleImages: boolean;
  handleThumbsButtonClick: (index: number) => void;
  handleMainSliderBeforeChange: (current: number, next: number) => void;
}

export const useGallery = (images: Product['images']): UseGalleryReturn => {
  const viewport = useViewport();
  const mainSliderRef = useRef<Slider | null>(null);
  const thumbsSliderRef = useRef<Slider | null>(null);

  const handleMainSliderBeforeChange = (_current: number, next: number): void => {
    if (thumbsSliderRef.current) {
      thumbsSliderRef.current.slickGoTo(next);
    }
  };

  const handleThumbsButtonClick = (index: number): void => (
    mainSliderRef.current?.slickGoTo(index)
  );

  const thumbsSlidesToShow = (images.length === 2 || viewport === Viewport.MOBILE) ? 2 : 3;

  const hasMultipleImages = images.length > 1;
  const mainSliderSettings: Settings = {
    accessibility: true,
    dots: hasMultipleImages,
    arrows: hasMultipleImages,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: false,
    lazyLoad: 'ondemand'
  };
  const thumbsSliderSettings: Settings = {
    accessibility: true,
    dots: false,
    arrows: false,
    infinite: false,
    speed: 200,
    slidesToShow: thumbsSlidesToShow,
    slidesToScroll: 1,
    focusOnSelect: false,
    asNavFor: thumbsSliderRef.current || undefined
  };

  return {
    mainSliderRef,
    thumbsSliderRef,
    mainSliderSettings,
    thumbsSliderSettings,
    hasMultipleImages,
    handleThumbsButtonClick,
    handleMainSliderBeforeChange
  };
};
