import classNames from 'classnames';
import { Location, useLocation } from 'react-router-dom';

export const addActiveClass = (slug: string, location: Location<unknown>, itemClass: string): string => (
  classNames(itemClass, {
    'xActive': location.pathname === `/${slug}`
  })
);

export const useLocationParams = (param: string): string | null => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return params.get(param);
};
