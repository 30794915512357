import { JSX } from 'react';
import { isEmpty } from 'utils/validation';
import Featured from '../../Featured/Featured';
import Listing from '../../Listing/Listing';
import Loading from '../../Loading/Loading';
import styles from './storeOverview.module.scss';
import { useStoreOverview } from './useStoreOverview';

const StoreOverview = (): JSX.Element => {
  const {
    t,
    isLoading,
    products,
    featuredProduct
  } = useStoreOverview();

  return (
    <section className={styles.bStoreOverview}>
      {isLoading || isEmpty(products) ?
        <Loading height={70} /> :
        <>
          {featuredProduct &&
            <Featured
              imgSrc={featuredProduct?.coverImage}
              name={featuredProduct?.title}
              url={featuredProduct?.link}
              description={featuredProduct?.content}/>}
          <Listing
            title={t('newProductsTitle')}
            maxItems={16}
            listItems={products}/>
        </>}
    </section>
  );
};

export default StoreOverview;
