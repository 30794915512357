import Button from 'components/Button/Button';
import CartItem from 'components/CartItem/CartItem';
import FlashMessage from 'components/FlashMessage/FlashMessage';
import Loading from 'components/Loading/Loading';
import { JSX } from 'react';
import { FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import { isEmpty, isNotEmpty } from 'utils/validation';
import { ROUTE_PATHS } from 'constants/paths';
import styles from './cartOverview.module.scss';
import { useCartOverview } from './useCartOverview';

const CartOverview = (): JSX.Element => {
  const {
    t,
    cart,
    isLoadingCart,
    isFailedOrder,
    handleCheckoutClick
  } = useCartOverview();

  return (
    <section className={styles.bCartOverview}>
      <div className={styles.titleWrapper}>
        <h1 className="h2">{t('cart.title')}</h1>
        <Link to={ROUTE_PATHS.home} className={styles.continueShoppingLink}>
          {t('cart.emptyContinue')}
        </Link>
      </div>
      {isFailedOrder &&
        <FlashMessage
          extensionClass={styles.flashMessage}
          message={t('errors.orderFailed')}
          type='error'/>}
      {isLoadingCart && <Loading height={150}/>}
      {!isLoadingCart && (isEmpty(cart) || isEmpty(cart?.items)) &&
        <p className={styles.empty}>{t('cart.empty')}</p>}
      {!isLoadingCart && (isNotEmpty(cart) && isNotEmpty(cart?.items)) &&
        <>
          <ul className={styles.itemsList}>
            {cart?.items.map(item => (
              <CartItem
                key={`${ item.id }${ item.product.id }`}
                item={item}
                isDisabled={item.outOfStock || item.product.outOfStock}
                isEditable />
            ))}
          </ul>
          <p className={styles.total}>
            <span className={styles.subtotal}>{t('cart.subTotal')}</span>
            <FormattedNumber
              value={Number(cart?.amount.value)}
              style='currency'
              currency={cart?.total.currencyCode}/>
          </p>
          <div className={styles.actions}>
            <Button onClick={handleCheckoutClick}>
              {t('cart.checkout')}
            </Button>
          </div>
        </>}
    </section>
  );
};

export default CartOverview;
