import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { queryMutation } from 'services/services';
import { FormValuesAddress } from 'types/forms';
import { GenericMutationResponse } from 'types/requests';
import { hasAccessToken } from 'constants/requests';

interface UseDeleteAddressReturn extends GenericMutationResponse<FormValuesAddress['id']> {
  deleteAddress: UseMutateFunction<Response, Error, FormValuesAddress['id'], unknown>;
}

export const useDeleteAddress = (): UseDeleteAddressReturn => {
  const queryClient = useQueryClient();
  const { mutate, ...args } = useMutation({
    mutationFn: (addressId?: FormValuesAddress['id']) => {
      if (hasAccessToken() && addressId) {
        return queryMutation(`user/addresses/${addressId}?`, 'DELETE');
      }
      return Promise.reject('No access token or addressId provided.');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAddresses'] });
    }
  });

  return {
    deleteAddress: mutate,
    ...args
  };
};
