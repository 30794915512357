import { useSetAtom } from 'jotai';
import { JSX, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetProductSearch } from 'services/getProductSearch/getProductSearch';
import { pageTitleAtom } from 'store/store';
import { useLocationParams } from 'utils/navigation';
import { isEmpty } from 'utils/validation';
import Listing from '../../Listing/Listing';
import Loading from '../../Loading/Loading';
import styles from './searchOverview.module.scss';

const SearchOverview = (): JSX.Element => {
  const { t } = useTranslation('global');
  const setPageTitle = useSetAtom(pageTitleAtom);

  const searchParam = useLocationParams('q');
  const searchFromUrl = searchParam ? searchParam : '';
  const { products, isLoading, isError } = useGetProductSearch(searchFromUrl);

  useEffect(() => {
    setPageTitle(t('search.results', { query: searchFromUrl }));
  }, [searchFromUrl, setPageTitle, t]);

  if (isLoading) {
    return <Loading height={70} message={t('search.placeholder', { query: searchFromUrl })} />;
  }

  if (isEmpty(products)) {
    return <p className={styles.bNoResults}>{t('search.noResults', { query: searchFromUrl })}</p>;
  }

  if (isError) {
    return <p className={styles.bNoResults}>{t('search.errorResults')}</p>;
  }

  return (
    <article className={styles.bSearchOverview}>
      <Listing
        listItems={products}
        title={t('search.results', { query: searchFromUrl })}/>
    </article>
  );
};

export default SearchOverview;
