import classNames from 'classnames';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import OrderItems from 'components/OrderItems/OrderItems';
import PricesList from 'components/PricesList/PricesList';
import { JSX } from 'react';
import { PaymentType, UserOrder } from 'types/order';
import styles from './myOrderDetail.module.scss';
import { useMyOrderDetail } from './useMyOrderDetail';

const MyOrderDetail = ({ order }: { order: UserOrder}): JSX.Element => {
  const { t, handlePayNowClick, isLoading } = useMyOrderDetail(order);
  const payNowButtonClasses = classNames('xSmall', {
    [styles.xLoadingButton]: isLoading
  });

  return (
    <section className={styles.bMyOrderDetail}>
      <div className={styles.addresses}>
        <div className={styles.address}>
          <h2 className={styles.title}>{t('orders.shippingAddress')}</h2>
          <address className={styles.addressText}>
            {order.deliveryAddress.firstName} {order.deliveryAddress.lastName}<br/>
            {order.deliveryAddress.street} {order.deliveryAddress.houseNumber}
            {order.deliveryAddress.houseNumberInfo && <br/>}
            {order.deliveryAddress.houseNumberInfo}<br/>
            {order.paymentAddress.postalCode} {order.deliveryAddress.city}<br/>
            {order.deliveryAddress.country?.name}
          </address>
        </div>
        <div className={styles.address}>
          <h2 className={styles.title}>{t('orders.billingAddress')}</h2>
          <address className={styles.addressText}>
            {order.paymentAddress.firstName} {order.paymentAddress.lastName}<br/>
            {order.paymentAddress.street} {order.paymentAddress.houseNumber}
            {order.deliveryAddress.houseNumberInfo && <br/>}
            {order.paymentAddress.houseNumberInfo}<br/>
            {order.paymentAddress.postalCode} {order.paymentAddress.city}<br/>
            {order.paymentAddress.country?.name}
          </address>
        </div>
      </div>
      <div className={styles.types}>
        <div className={styles.detailItem}>
          <h2 className={styles.title}>{t('orders.paymentMethod')}</h2>
          <p className={styles.addressText}>{order.paymentType.name}</p>
          {(!order.paid && order.paymentType.id === PaymentType.CreditCard) &&
            <Button
              extensionClass={payNowButtonClasses}
              icon={isLoading ? 'flame' : undefined}
              onClick={handlePayNowClick}>
              {t('checkout.payNow')}
            </Button>}
          {order.paid &&
            <p className={styles.paid}>
              <Icon iconName='check'/>
              {t('orders.orderPayed')}
            </p>}
        </div>
        <div className={styles.detailItem}>
          <h2 className={styles.title}>{t('orders.shippingMethod')}</h2>
          <p className={styles.addressText}>{order.deliveryType.name}</p>
        </div>
      </div>
      <OrderItems orderItems={order.items}/>
      <PricesList
        amountWithoutTax={order.amountWithoutTax}
        amount={order.amount}
        deliveryCosts={order.deliveryCosts}
        total={order.total}/>
    </section>
  );
};

export default MyOrderDetail;
