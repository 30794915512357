import classNames from 'classnames';
import { TFunction } from 'i18next';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cartIdAtom, cartItemsQuantityAtom } from 'store/store';
import { Viewport } from 'types/viewports';
import { useViewport } from 'utils/useViewport';
import styles from './quickLinks.module.scss';

interface UseQuickLinksProps {
  isMobileNav?: boolean;
  isMobileSearchClosed?: boolean;
}

interface UseQuickLinksReturn {
  quickLinksClasses: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleButtonClick: (itemName: string) => (() => void) | undefined;
  t: TFunction<'global', undefined>;
  viewport: Viewport | '';
  cartItemsQuantity: number;
}

export const useQuickLinks = ({ isMobileSearchClosed, isMobileNav }: UseQuickLinksProps): UseQuickLinksReturn => {
  const { t } = useTranslation('global');
  const viewport = useViewport();
  const [isOpen, setIsOpen] = useState(false);
  const cartId = useAtomValue(cartIdAtom);
  const cartItemsQuantity = useAtomValue(cartItemsQuantityAtom);

  useEffect(() => {
    if (isMobileSearchClosed) {
      setIsOpen(false);
    }
  }, [isMobileSearchClosed]);

  const quickLinksClasses = classNames(styles.bQuickLinks, {
    [styles.xMobileNav]: isMobileNav
  });

  const handleOpenSearch = (): void => {
    document.body.classList.add('xNoScroll');
    setIsOpen(true);
  };

  const handleButtonClick = (itemName: string): (() => void) | undefined => (
    itemName === 'headerLinks.search' ? handleOpenSearch : undefined
  );

  return {
    quickLinksClasses,
    isOpen,
    setIsOpen,
    handleButtonClick,
    t,
    viewport,
    cartItemsQuantity: cartId ? cartItemsQuantity || 0 : 0
  };
};
