import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { queryMutation } from 'services/services';
import { cartIdAtom } from 'store/store';
import { Cart } from 'types/cart';
import { UpdateCart } from 'types/checkout';
import { GenericMutationResponse } from 'types/requests';

interface UseUpdateCartReturn extends GenericMutationResponse<UpdateCart> {
  updateCart: UseMutateFunction<Response, Error, UpdateCart, unknown>;
  newCart?: Cart;
  isUpdatingCart: boolean;
}

export const useUpdateCart = (): UseUpdateCartReturn => {
  const cartId = useAtomValue(cartIdAtom);
  const queryClient = useQueryClient();

  const { mutate, ...args } = useMutation({
    mutationFn: (updatedCart: UpdateCart) => {
      return queryMutation(`shopping-cart/${cartId}?`, 'PATCH', updatedCart);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getCart'] });
    }
  });

  return {
    updateCart: mutate,
    isUpdatingCart: args.isPending || args.isError,
    ...args
  };
};
