import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateCartItem } from 'services/updateCart/updateCartItem';
import { CartItemType } from 'types/cart';
import { SelectOption } from 'types/sorting';

interface UseCartItemReturn {
  t: TFunction<'global', undefined>;
  quantityOption: SelectOption;
  handleQuantityChange: (selectedOption?: SelectOption | unknown) => void;
  isPending?: boolean;
  productColor?: string;
  productSize?: string;
  handleRemoveClick: () => void;
}

export const useCartItem = (item: CartItemType): UseCartItemReturn => {
  const { t } = useTranslation('global');
  const { updateCartItem, isPending } = useUpdateCartItem();

  const [quantity, setQuantity] = useState<SelectOption>({
    value: item.quantity.toString(),
    label: item.quantity.toString()
  });

  useEffect(() => {
    setQuantity({
      value: item.quantity.toString(),
      label: item.quantity.toString()
    });
  }, [item.quantity]);

  const handleQuantityChange = (selectedOption?: SelectOption | unknown): void => {
    if (selectedOption) {
      setQuantity(selectedOption as SelectOption);
      updateCartItem({
        ...item,
        quantity: Number((selectedOption as SelectOption).value)
      });
    }
  };

  const sizeAttribute = item.attributes.find(attr => attr.group.id === 1);
  const colorAttribute = item.attributes.find(attr => attr.group.id === 2);
  const productSize = sizeAttribute?.name;
  const productColor = colorAttribute?.name;

  const handleRemoveClick = (): void => {
    updateCartItem({
      ...item,
      quantity: 0
    });
  };

  return {
    t,
    isPending,
    quantityOption: quantity,
    handleQuantityChange,
    productSize,
    productColor,
    handleRemoveClick
  };
};
