import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

interface UseOrderItemsReturn {
  t: TFunction<'global', undefined>;
}

export const useOrderItems = (): UseOrderItemsReturn => {
  const { t } = useTranslation('global');

  return {
    t
  };
};
