import { FormikHelpers } from 'formik';
import { TFunction } from 'i18next';
import { useSetAtom } from 'jotai';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReset } from 'services/updateUser/password/reset';
import { pageTitleAtom } from 'store/store';
import { FormValuesResetPassword } from 'types/forms';
import { NetworkErrorResponse } from 'types/requests';
import { useHandleErrors } from 'utils/handleErrors';
import { useLocationParams } from 'utils/navigation';

interface UseResetPasswordReturn {
  t: TFunction<'global', undefined>;
  isSuccessRequest: boolean;
  initialValues: FormValuesResetPassword;
  setFieldErrorRef: MutableRefObject<(field: string, message: string) => (void | null)>;
  handleSubmit: (values: FormValuesResetPassword, { setSubmitting }: FormikHelpers<FormValuesResetPassword>) => void;
}

export const useResetPassword = (): UseResetPasswordReturn => {
  const { t } = useTranslation('global');
  const setPageTitle = useSetAtom(pageTitleAtom);
  const metaTitle = `${t('login.resetPassword')} | ${t('pageTitle')} ${t('pageTitleSub')}`;

  const resetCode = useLocationParams('code');

  const setFieldErrorRef = useRef<(field: string, message: string) => void | null>(null) as MutableRefObject<(field: string, message: string) => void | null>;
  const [isSuccessRequest, setIsSuccessRequest] = useState(false);
  const { resetPassword, isSuccess, data } = useReset(setIsSuccessRequest) as {
    resetPassword: (values: FormValuesResetPassword) => void;
    isSuccess: boolean;
    data: NetworkErrorResponse;
  };

  const initialValues: FormValuesResetPassword = {
    code: resetCode || '',
    password: '',
    passwordConfirmation: ''
  };

  const handleSubmit = (values: FormValuesResetPassword, { setSubmitting }: FormikHelpers<FormValuesResetPassword>): void => {
    resetPassword(values);
    setSubmitting(false);
  };

  useEffect(() => {
    setPageTitle(metaTitle);
  }, [metaTitle, setPageTitle]);

  useHandleErrors({
    isSuccess,
    data,
    setFieldErrorRef,
    t
  });

  return {
    t,
    isSuccessRequest,
    setFieldErrorRef,
    initialValues,
    handleSubmit
  };
};
