import Cookies from 'js-cookie';
import { FormValuesLogin } from 'types/forms';
import { queryMutation } from '../services';

export interface LoginResponse {
  token: string;
}

const login = async (values: FormValuesLogin): Promise<LoginResponse> => {
  const data = await queryMutation('auth?', 'POST', values) as unknown as LoginResponse;

  if (data.token) {
    Cookies.set('siddharta_access', data.token, {
      secure: true,
      sameSite: 'strict',
      expires: 2 / 24 // 2h
    });
  }

  return data;
};

export default login;
