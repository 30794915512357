import { TFunction } from 'i18next';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUserOrders } from 'services/getUserOrders/getUserOrders';
import { pageTitleAtom } from 'store/store';
import { UserOrder } from 'types/order';
import { useLocationParams } from 'utils/navigation';

interface UseMyOrdersReturn {
  t: TFunction<'global', undefined>;
  isLoading?: boolean;
  isError?: boolean;
  isFailedOrder?: boolean;
  orders?: UserOrder[];
}

export const useMyOrders = (): UseMyOrdersReturn => {
  const { t } = useTranslation('global');
  const { orders, isLoading, isError } = useGetUserOrders();
  const setPageTitle = useSetAtom(pageTitleAtom);

  const errorParam = useLocationParams('error');
  const [isFailedOrder, setIsFailedOrder] = useState(false);

  useEffect(() => {
    setPageTitle(t('login.myOrders'));
  }, [t, setPageTitle]);

  useEffect(() => {
    setIsFailedOrder(Boolean(errorParam));
  }, [errorParam]);

  // TODO: remove once it comes correct fromAPI; sort orders by date
  (orders as UserOrder[])?.sort((a, b) => {
    return new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime();
  });

  return {
    t,
    isLoading,
    isError,
    isFailedOrder,
    orders: orders as UserOrder[]
  };
};
