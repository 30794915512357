import { ErrorMessage, Field, Form, Formik } from 'formik';
import { JSX } from 'react';
import { validationAccount } from 'constants/validation';
import Button from '../../Button/Button';
import styles from './accountData.module.scss';
import { useAccountData } from './useAccountData';

const AccountData = (): JSX.Element => {
  const {
    t,
    isEmailReadOnly,
    initialValues,
    setFieldErrorRef,
    handleSubmit
  } = useAccountData();

  return (
    <section className={styles.bAccountData}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationAccount(t)}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={handleSubmit}>
        {({ isSubmitting, isValid, dirty, setFieldError }) => {
          setFieldErrorRef.current = setFieldError;

          return (
            <Form className='form xWide'>
              <Field
                autoComplete='given-name'
                type='text'
                name='firstName'
                className='input'
                id='firstName'
                placeholder={t('address.firstName')}/>
              <ErrorMessage
                name='firstName'
                component='p'
                className='inlineError'/>

              <Field
                autoComplete='family-name'
                type='text'
                name='lastName'
                className='input'
                id='lastName'
                placeholder={t('address.lastName')}/>
              <ErrorMessage
                name='lastName'
                component='p'
                className='inlineError'/>

              <Field
                autoComplete='email'
                type='email'
                name='email'
                className='input'
                id='email'
                readOnly={isEmailReadOnly}
                placeholder={t('login.email')}/>
              <ErrorMessage
                name='email'
                component='p'
                className='inlineError'/>

              <div className='checkboxWrapper'>
                <Field
                  type='checkbox'
                  name='newsletterActive'
                  id='account-info-emails'
                  className='checkbox'/>
                <label
                  htmlFor='account-info-emails'
                  className='checkLabel'>
                  <span className='labelText'>
                    {t('login.infoEmails')}
                  </span>
                </label>
                <ErrorMessage
                  name='newsletterActive'
                  component='p'
                  className='inlineError'/>
              </div>

              <h2 className='h3'>{t('login.changePassword')}</h2>
              <p>{t('login.passwordConditions', { min: 10 })}</p>
              <Field
                autoComplete='current-password'
                name='oldPassword'
                className='input'
                id='oldPassword'
                placeholder={t('login.oldPassword')}
                type='password'/>
              <ErrorMessage
                name='oldPassword'
                component='p'
                className='inlineError'/>

              <Field
                autoComplete='new-password'
                name='password'
                className='input'
                id='password'
                placeholder={t('login.newPassword')}
                type='password'/>
              <ErrorMessage
                name='password'
                component='p'
                className='inlineError'/>

              <Field autoComplete='off'
                name='passwordConfirmation'
                className='input'
                id='passwordConfirm'
                placeholder={t('login.passwordConfirm')}
                type='password'/>
              <ErrorMessage
                name='passwordConfirmation'
                component='p'
                className='inlineError'/>

              <Button
                type='submit'
                disabled={isSubmitting || !isValid || !dirty}
                extensionClass='submitButton'
                icon={isSubmitting ? 'check' : undefined}>
                {t('login.save')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default AccountData;
