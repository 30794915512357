import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { Dispatch, SetStateAction } from 'react';
import { queryMutation } from 'services/services';
import { GenericMutationResponse, NetworkErrorResponse } from 'types/requests';

interface UseConfirmUserReturn extends GenericMutationResponse<string> {
  confirmUser: UseMutateFunction<Response | NetworkErrorResponse, Error, string, unknown>;
}

export const useConfirmUser = (
  setIsSuccessEmail: Dispatch<SetStateAction<boolean>>,
  setTokenErrorMsg:  Dispatch<SetStateAction<string>>): UseConfirmUserReturn => {
  const { mutate, ...args } = useMutation({
    mutationFn: (token: string) => {
      return queryMutation('user/confirm-registration?', 'POST', { token });
    },
    onSuccess: (data: Response | NetworkErrorResponse) => {
      if (data.status === 200) setIsSuccessEmail(true);
      else if ('invalidParams' in data && data.invalidParams) setTokenErrorMsg(data.invalidParams[0].key);
      else {
        if ('detail' in data) {
          setTokenErrorMsg(data.detail);
        }
      }
    },
    onError: (error) => {
      setTokenErrorMsg(error.message);
    }
  });

  return {
    confirmUser: mutate,
    ...args
  };
};
