import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryMutation } from 'services/services';
import { FormValuesRegister } from 'types/forms';
import { GenericMutationResponse } from 'types/requests';

interface UseAddUserReturn extends GenericMutationResponse<FormValuesRegister> {
  addUser: UseMutateFunction<Response, Error, FormValuesRegister, unknown>;
}

export const useAddUser = (): UseAddUserReturn  => {
  const { mutate, ...args } = useMutation({
    mutationFn: (newUser: FormValuesRegister) => {
      return queryMutation('users?', 'POST', newUser);
    }
  });

  return {
    addUser: mutate,
    ...args
  };
};
