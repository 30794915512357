import { useQuery } from '@tanstack/react-query';
import { GetUserOrders } from 'types/requests';
import { fetchUserOrders } from 'utils/fetch';
import { hasAccessToken } from 'constants/requests';

export const useGetUserOrders = (orderId?: string | null): GetUserOrders => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ['getUserOrders', orderId],
    queryFn: () => fetchUserOrders(orderId),
    enabled: hasAccessToken()
  });

  return {
    orders: data,
    isLoading,
    isError
  };
};
