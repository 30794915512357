import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import styles from './featured.module.scss';

interface FeaturedProps {
  url?: string;
  name?: string;
  imgSrc?: string;
  description?: string;
}

const Featured = ({ url, name, imgSrc, description }: FeaturedProps): JSX.Element => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  
  const handleLinkClick = (): void => (
    navigate(`/${url}`)
  );
  
  return (
    <figure className={styles.bFeatured}>
      {imgSrc &&
        <div className={styles.featuredImgWrapper}>
          {url ? (
            <Link to={url} aria-label={name}>
              <img
                className={styles.featuredImg}
                src={imgSrc}
                width='500'
                alt={name}/>
            </Link>
          ) : (
            <img
              className={styles.featuredImg}
              src={imgSrc}
              width='500'
              alt={name}/>
          )}
        </div>}
      <figcaption className={styles.featuredText}>
        {name && <h1 className={styles.name}>{name}</h1>}
        {description &&
          <p className={styles.description}>{description}</p>}
        {url &&
          <Button
            onClick={handleLinkClick}
            aria-label={t('gotoLink', { name })}>
            {t('ctaText')}
          </Button>}
      </figcaption>
    </figure>
  );
};

export default Featured;
