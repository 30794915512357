import { FormikHelpers } from 'formik';
import { TFunction } from 'i18next';
import { useSetAtom } from 'jotai';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForgot } from 'services/updateUser/password/forgot';
import { pageTitleAtom } from 'store/store';
import { FormValuesForgotPassword } from 'types/forms';
import { NetworkErrorResponse } from 'types/requests';
import { useHandleErrors } from 'utils/handleErrors';

interface UseForgotPasswordReturn {
  t: TFunction<'global', undefined>;
  initialValues: FormValuesForgotPassword;
  isSuccessRequest: boolean;
  setFieldErrorRef: MutableRefObject<(field: string, message: string) => (void | null)>;
  handleSubmit: (values: FormValuesForgotPassword, { setSubmitting }: FormikHelpers<FormValuesForgotPassword>) => void;
}

export const useForgotPassword = (): UseForgotPasswordReturn => {
  const { t } = useTranslation('global');
  const setPageTitle = useSetAtom(pageTitleAtom);
  const metaTitle = `${t('login.forgotPassword')} | ${t('pageTitle')} ${t('pageTitleSub')}`;

  const setFieldErrorRef = useRef<(field: string, message: string) => void | null>(null) as MutableRefObject<(field: string, message: string) => void | null>;
  const [isSuccessRequest, setIsSuccessRequest] = useState(false);
  const { forgotPassword, isSuccess, data } = useForgot(setIsSuccessRequest) as {
    forgotPassword: (email: string) => void;
    isSuccess: boolean;
    data: NetworkErrorResponse;
  };

  const initialValues: FormValuesForgotPassword = {
    email: ''
  };

  const handleSubmit = (values: FormValuesForgotPassword, { setSubmitting }: FormikHelpers<FormValuesForgotPassword>): void => {
    forgotPassword(values.email);
    setSubmitting(false);
  };

  useEffect(() => {
    setPageTitle(metaTitle);
  }, [metaTitle, setPageTitle]);

  useHandleErrors({
    isSuccess,
    data,
    setFieldErrorRef,
    t
  });

  return {
    t,
    initialValues,
    isSuccessRequest,
    setFieldErrorRef,
    handleSubmit
  };
};
