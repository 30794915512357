import classNames from 'classnames';
import { JSX, useEffect } from 'react';
import styles from './backgroundCover.module.scss';

interface BackgroundCoverProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const BackgroundCover = ({ isOpen, setIsOpen }: BackgroundCoverProps): JSX.Element => {
  const closeMobileNav = (): void => {
    document.body.classList.remove('xNoScroll');
    setIsOpen(false);
  };

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      closeMobileNav();
    }
  };
  
  const backgroundClasses = classNames(styles.bBackground, {
    [styles.xHidden]: !isOpen
  });

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return (): void => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <span
      aria-hidden='true'
      onClick={closeMobileNav}
      className={backgroundClasses}/>
  );
};

export default BackgroundCover;
