import { useMutation } from '@tanstack/react-query';
import { FormikHelpers } from 'formik';
import { TFunction } from 'i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import login, { LoginResponse } from 'services/login/login';
import { useConfirmUser } from 'services/updateUser/confirmUser';
import { pageTitleAtom, previousUrlAtom } from 'store/store';
import { FormValuesLogin } from 'types/forms';
import { NetworkErrorResponse } from 'types/requests';
import { redirectAuthenticated } from 'utils/auth';
import { useLocationParams } from 'utils/navigation';
import { isNotEmpty, isNotUndefined } from 'utils/validation';
import { ROUTE_PATHS } from 'constants/paths';
import { API_URL_OAUTH } from 'constants/requests';

interface UseLoginReturn {
  t: TFunction<'global', undefined>;
  socialLoginLinkGoogle: string;
  socialLoginLinkFacebook: string;
  isSuccessEmail: boolean;
  tokenErrorMsg: string;
  initialValues: FormValuesLogin;
  handleSubmit: (values: FormValuesLogin, { setSubmitting }: FormikHelpers<FormValuesLogin>) => void;
}

export const useLogin = (): UseLoginReturn => {
  const { t } = useTranslation('global');

  const hasConfirmedUser = useRef(false);
  const emailToken = useLocationParams('token');

  const [isSuccessEmail, setIsSuccessEmail] = useState(false);
  const [tokenErrorMsg, setTokenErrorMsg] = useState('');
  const { confirmUser } = useConfirmUser(setIsSuccessEmail, setTokenErrorMsg);

  const previousUrlStored = useAtomValue(previousUrlAtom);
  const setPageTitle = useSetAtom(pageTitleAtom);
  const metaTitle = `${t('login.title')} | ${t('pageTitle')} ${t('pageTitleSub')}`;

  const comesFromCart = previousUrlStored.includes(ROUTE_PATHS.cart);
  const socialLoginLinkGoogle = `${API_URL_OAUTH}/google${comesFromCart ? '?returnTo=checkout' : ''}`;
  const socialLoginLinkFacebook = `${API_URL_OAUTH}/facebook${comesFromCart ? '?returnTo=checkout' : ''}`;

  const initialValues: FormValuesLogin = {
    email: '',
    password: ''
  };

  const { mutate } = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      if (data.token) {
        redirectAuthenticated(previousUrlStored);
      }
    }
  });

  const handleSubmit = (values: FormValuesLogin, { setSubmitting, setFieldError }: FormikHelpers<FormValuesLogin>): void => {
    const isNetworkError = (data: LoginResponse | NetworkErrorResponse): data is NetworkErrorResponse => (
      isNotUndefined((data as NetworkErrorResponse).status)
    );

    setSubmitting(false);
    mutate(values, {
      onSuccess: (data) => {
        if (isNetworkError(data) && data.status >= 400 && data.status < 500) {
          setFieldError('password', t(data.key));
        }
      },
      onError: error => setFieldError('password', error.message)
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPageTitle(metaTitle);
  }, [metaTitle, setPageTitle]);

  useEffect(() => {
    if (emailToken && isNotEmpty(emailToken) && !isSuccessEmail && !tokenErrorMsg && !hasConfirmedUser.current) {
      confirmUser(emailToken);
      hasConfirmedUser.current = true;
    }
  }, [confirmUser, emailToken, isSuccessEmail, tokenErrorMsg]);

  return {
    t,
    socialLoginLinkGoogle,
    socialLoginLinkFacebook,
    isSuccessEmail,
    tokenErrorMsg,
    initialValues,
    handleSubmit
  };
};
