import { useQuery } from '@tanstack/react-query';
import { GetCategories } from 'types/requests';
import { fetchCategories } from 'utils/fetch';
import { URL_LISTINGS_PREFIX } from 'constants/paths';

export const useGetCategories = (): GetCategories => {
  const { data, isError, isLoading, isSuccess } = useQuery({
    queryKey: ['getCategories'],
    queryFn: () => fetchCategories()
  });

  const updatedData = data?.map(category => ({
    ...category,
    slug: `${URL_LISTINGS_PREFIX}/${category.slug}`,
    children: category.children ?
      category.children.map(subCategory => ({
        ...subCategory,
        slug: `${URL_LISTINGS_PREFIX}/${subCategory.slug}`
      })) : []
  }));

  return {
    isLoading,
    categories: updatedData,
    isError,
    isSuccess
  };
};
