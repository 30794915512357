import { JSX, RefObject } from 'react';
import BackgroundCover from '../BackgroundCover/BackgroundCover';
import Icon from '../Icon/Icon';
import styles from './search.module.scss';
import { useSearch } from './useSearch';

interface SearchProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isMobileNav?: boolean;
  searchInputRef?: RefObject<HTMLInputElement>;
}

const Search = ({ isOpen, setIsOpen, isMobileNav }: SearchProps): JSX.Element => {
  const {
    t,
    searchClasses,
    searchInputRef,
    submitButtonRef,
    closeButtonRef,
    closeMobileNav,
    handleSearchSubmit
  } = useSearch(isOpen, setIsOpen, isMobileNav);

  return (
    <article className={searchClasses} role='search' aria-label={t('headerLinks.siteWide')}>
      {!isMobileNav &&
        <BackgroundCover isOpen={isOpen} setIsOpen={setIsOpen}/>}
      <div className={styles.wrapper}>
        <form className={styles.searchWrapper} onSubmit={handleSearchSubmit}>
          <input
            id='search'
            className={styles.field}
            type='search'
            placeholder={t('headerLinks.search')}
            ref={searchInputRef} />
          <label className='visuallyhidden' htmlFor='search'>{t('headerLinks.search')}</label>
          <button
            type='submit'
            ref={submitButtonRef}
            className={styles.submitButton}
            aria-labelledby='search'>
            <Icon iconName='search' iconTitle={t('headerLinks.search')} extensionClass={styles.submitIcon} />
          </button>
        </form>
        <button
          type='button'
          ref={closeButtonRef}
          className={styles.closeButton}
          onClick={closeMobileNav}>
          <Icon iconName='close' iconTitle={t('ctaClose', { name: t('headerLinks.search') })} extensionClass={styles.closeIcon} />
        </button>
      </div>
    </article>
  );
};

export default Search;
