import { TFunction } from 'i18next';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetAddresses } from 'services/getAddresses/getAddresses';
import { pageTitleAtom } from 'store/store';
import { User } from 'types/user';
import { ROUTE_PATHS } from 'constants/paths';

interface UseAddressesReturn {
  t: TFunction<'global', undefined>;
  addresses?: User['addresses'];
  isLoading?: boolean;
  handleAddClick(): void;
}

export const useAddresses = (): UseAddressesReturn => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const { addresses, isLoading } = useGetAddresses();
  const setPageTitle = useSetAtom(pageTitleAtom);

  useEffect(() => {
    setPageTitle(t('login.addresses'));
  }, [setPageTitle, t]);

  const handleAddClick = (): void => {
    navigate(ROUTE_PATHS.addAddress);
  };

  return {
    t,
    addresses,
    isLoading,
    handleAddClick
  };
};

