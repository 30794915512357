import Icon from 'components/Icon/Icon';
import { JSX } from 'react';
import { isEmpty } from 'utils/validation';
import Loading from '../../Loading/Loading';
import Address from '../Address/Address';
import styles from './addresses.module.scss';
import { useAddresses } from './useAddresses';

const Addresses = (): JSX.Element => {
  const { t, addresses, isLoading, handleAddClick } = useAddresses();

  return (
    <section className={styles.bAddresses}>
      {isLoading && isEmpty(addresses) ?
        <Loading height={80} extensionClass='xLeftPadding'/> :
        <>
          {addresses && addresses.map(address => (
            <Address key={address.id} address={address}/>
          ))}
          <button
            className={styles.addAddressButton}
            type='button'
            onClick={handleAddClick}>
            <Icon iconName='close'/> {t('address.addAddress')}
          </button>
        </>
      }
    </section>
  );
};

export default Addresses;
