import { useQuery } from '@tanstack/react-query';
import { GetPage } from 'types/requests';
import { fetchPage } from 'utils/fetch';

export const useGetPage = (pageId?: string): GetPage => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ['getPage', pageId],
    queryFn: () => fetchPage(pageId)
  });

  return {
    page: data,
    isLoading,
    isError
  };
};
