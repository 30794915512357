import { useQuery } from '@tanstack/react-query';
import { GetFeatured } from 'types/requests';
import { fetchFeaturedProduct } from 'utils/fetch';

export const useGetFeatured = (): GetFeatured => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ['getFeatured'],
    queryFn: () => fetchFeaturedProduct()
  });

  return {
    featuredProduct: data,
    isLoading,
    isError
  };
};
