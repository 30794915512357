import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import { ListProduct } from 'types/products';
import Availability from '../Availability/Availability';
import styles from './listItem.module.scss';

interface ListItemProps {
  item: ListProduct;
}

const ListItem = ({ item }: ListItemProps): JSX.Element => {
  const { t } = useTranslation('global');
  
  return (
    <li key={item.id} className={styles.bListItem}>
      <Link className={styles.listLink} to={item.slug} aria-label={t('gotoLink', { name: item.name })}>
        <img className={styles.itemImg} src={item.imgSrc} alt={item.name}/>
        <h2 className={styles.name}>{item.name} <span className={styles.type}>({item.type})</span></h2>
        <span className={styles.metaWrapper}>
          <em className={styles.price}>
            <FormattedNumber
              value={Number(item.price.value)}
              style='currency'
              currency={item.price.currencyCode}/>
          </em>
          {item.availability &&
            <Availability
              availability={item.availability}
              isSmall />}
        </span>
      </Link>
    </li>
  );
};

export default ListItem;
