import classNames from 'classnames';
import EditAddress from 'components/_account/EditAddress/EditAddress';
import styles from 'components/_overviews/checkout/CheckoutOverview/checkoutOverview.module.scss';
import { useCheckoutOverview } from 'components/_overviews/checkout/CheckoutOverview/useCheckoutOverview';
import Button from 'components/Button/Button';
import FlashMessage from 'components/FlashMessage/FlashMessage';
import Icon from 'components/Icon/Icon';
import Loading from 'components/Loading/Loading';
import ModalWrapper from 'components/Modal/Modal';
import OrderItems from 'components/OrderItems/OrderItems';
import PricesList from 'components/PricesList/PricesList';
import RadioSelect from 'components/RadioSelect/RadioSelect';
import { JSX } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty, isNotEmpty } from 'utils/validation';
import { ROUTE_PATHS } from 'constants/paths';

const CheckoutOverview = (): JSX.Element => {
  const { t,
    checkoutItems,
    cart,
    isLoading,
    addressOptions,
    addressId,
    addressPaymentId,
    setAddressPaymentId,
    shippingMethods,
    shippingMethod,
    setShippingMethod,
    paymentMethods,
    paymentMethod,
    setPaymentMethod,
    setAddressId,
    setModalOpenName,
    isSameAsShipping,
    isFailedOrder,
    isDisabledSummaryButton,
    handleCheckBoxChange,
    handleCartClick,
    handleSummaryClick } = useCheckoutOverview();

  const addressButtonClasses = classNames(styles.buttonAddress, {
    [styles.xLarge]: isEmpty(addressOptions)
  });

  return (
    <article className={styles.bCheckoutOverview}>
      <h1 className='h2'>{t('checkout.title')}</h1>
      {isFailedOrder &&
        <FlashMessage
          extensionClass={styles.flashMessage}
          message={t('errors.orderFailed')}
          type='error'/>}
      {isLoading && <Loading height={100} />}
      {!isLoading &&
        <>
          <section className={styles.clientData}>
            <section className={styles.checkoutSection}>
              {addressId && <Icon iconName='check'/>}
              <h2 className='h3'>
                {t('checkout.shipping')}
                <Link to={ROUTE_PATHS.addresses} target='_blank' className={styles.editAddressesLink}>({t('address.editAddresses')})</Link></h2>
              {(addressOptions && isNotEmpty(addressOptions)) &&
                <RadioSelect
                  options={addressOptions}
                  setValue={setAddressId}
                  value={addressId}
                  radioName='addressesShipping'
                  extensionClass='xAddressRadio'/>}
              <button
                className={addressButtonClasses} type='button'
                onClick={() => setModalOpenName('modalAdressAdd')}>
                <Icon iconName='close'/>
                {isEmpty(addressOptions) ? t('checkout.addNewAddress') : t('checkout.shipToDifferentAddress')}
              </button>
              <ModalWrapper>
                <EditAddress isCheckout/>
              </ModalWrapper>
            </section>
            <section className={styles.checkoutSection}>
              {shippingMethod && <Icon iconName='check'/>}
              <h2 className='h3'>{t('orders.shippingMethod')}</h2>
              {!shippingMethods &&
                <p className={styles.noShippingMethod}>{t('checkout.noShippingMethodsYet')}</p>}
              {shippingMethods && isNotEmpty(shippingMethods) &&
                <RadioSelect
                  options={shippingMethods}
                  setValue={setShippingMethod}
                  value={shippingMethod}
                  radioName='shippingMethods'
                  extensionClass='xMethodsRadio'/>}
            </section>
            <section className={styles.checkoutSection}>
              {paymentMethod && <Icon iconName='check'/>}
              <h2 className='h3'>{t('orders.paymentMethod')}</h2>
              {paymentMethods && isNotEmpty(paymentMethods) &&
                <RadioSelect
                  options={paymentMethods}
                  setValue={setPaymentMethod}
                  value={paymentMethod}
                  radioName='paymentMethods'
                  extensionClass='xMethodsRadio'/>}
            </section>
            <section className={classNames(styles.checkoutSection, 'form')}>
              {addressPaymentId && <Icon iconName='check'/>}
              <h2 className='h3'>{t('orders.billingAddress')}</h2>
              <div className='checkboxWrapper'>
                <input
                  type='checkbox'
                  id='sameAddress'
                  name='sameAddress'
                  className='checkbox'
                  checked={isSameAsShipping}
                  onChange={handleCheckBoxChange}/>
                <label
                  htmlFor='sameAddress'
                  className='checkLabel'>
                  <span className='labelText'>
                    {t('checkout.useShippingAddress')}
                  </span>
                </label>
              </div>
              {!isSameAsShipping &&
                <section className={styles.checkoutSection}>
                  {(addressOptions && isNotEmpty(addressOptions)) &&
                    <RadioSelect
                      options={addressOptions}
                      setValue={setAddressPaymentId}
                      value={addressPaymentId}
                      radioName='addressesPayment'
                      extensionClass='xAddressRadio'/>}
                  <button
                    className={styles.buttonAddress} type='button'
                    onClick={() => setModalOpenName('modalAdressAdd')}>
                    <Icon iconName='close'/>{t('checkout.shipToDifferentAddress')}
                  </button>
                  <ModalWrapper>
                    <EditAddress isCheckout/>
                  </ModalWrapper>
                </section>}
            </section>
          </section>
          <section className={styles.cartItems}>
            <div className={styles.stickyWrapper}>
              <h2 className='h3'>{t('orders.itemsOrdered')}</h2>
              {checkoutItems &&
              <OrderItems orderItems={checkoutItems} isCheckout/>}
              <PricesList
                amountWithoutTax={cart?.amountWithoutTax}
                amount={cart?.amount}
                deliveryCosts={cart?.deliveryCosts}
                total={cart?.total}/>
            </div>
          </section>
        </>}
      <div className={styles.buttonsWrapper}>
        <Button
          onClick={handleCartClick}
          extensionClass='xButtonCart'
          icon='chevron-left'>
          {t('cart.title')}
        </Button>
        <Button
          onClick={handleSummaryClick}
          extensionClass='xButtonSummary'
          disabled={isDisabledSummaryButton}
          iconRight='chevron-right'>
          {t('checkout.summary')}
        </Button>
      </div>
    </article>
  );
};

export default CheckoutOverview;
