import { TFunction } from 'i18next';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetPaymentSession } from 'services/getPaymentSession/getPaymentSession';
import { stripeSecretAtom } from 'store/store';
import { UserOrder } from 'types/order';
import { isEmpty, isNotEmpty } from 'utils/validation';

interface UseMyOrderDetailReturn {
  t: TFunction<'global', undefined>;
  isLoading?: boolean;
  handlePayNowClick: () => void;
}

export const useMyOrderDetail = (order: UserOrder): UseMyOrderDetailReturn => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState<string>('');
  const { paymentSession, isLoading } = useGetPaymentSession(orderId);
  const [stripeSecret, setStripeSecret] = useAtom(stripeSecretAtom);

  useEffect(() => {
    if (isEmpty(stripeSecret) && paymentSession?.clientSecret) {
      setStripeSecret(paymentSession.clientSecret);
    }
  }, [paymentSession?.clientSecret, setStripeSecret, stripeSecret]);

  useEffect(() => {
    if (isNotEmpty(stripeSecret)) {
      navigate(`/checkout/payment?orderId=${orderId}`);
    }
  }, [navigate, orderId, stripeSecret]);

  const handlePayNowClick = (): void => {
    if (order.id) {
      setOrderId(order.id.toString());
    }
  };

  return {
    t,
    isLoading,
    handlePayNowClick
  };
};
