import classNames from 'classnames';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { StylesConfig } from 'react-select';
import { SelectOption } from 'types/sorting';
import styles from './selectBox.module.scss';

interface SelectBoxProps {
  id: string;
  options?: SelectOption[];
  placeholder: string;
  name?: string;
  defaultValue?: SelectOption;
  label?: string;
  classes?: string;
  onChange?: (option: SelectOption | unknown) => void;
  selectedOption?: SelectOption;
  disabled?: boolean;
  required?: boolean;
  hasError?: boolean;
}

const SelectBox = ({ id, options, selectedOption, classes, label, defaultValue, placeholder, onChange, disabled, required, hasError }: SelectBoxProps): JSX.Element => {
  const { t } = useTranslation('global');
  const customSelectStyles: StylesConfig = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? 'var(--color-primary-light)' : 'var(--color-background)',
      color: 'var(--color-on-background)'
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      borderColor: hasError ? 'var(--color-error)' :
        state.isFocused ? 'var(--color-border)' : 'var(--color-primary)',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'var(--color-primary)'
      }
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: 'var(--color-primary)',
      '&:hover': {
        borderColor: 'var(--color-on-background)'
      },
      transition: 'transform .3s ease 0s',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'
      // Add any additional styles for the dropdown indicator here
    }),
    menu: provided => ({
      ...provided,
      borderRadius: 0,
      marginTop: 0
    })
  };

  const getTranslatedOptions = (rawOptions?: SelectOption[]): SelectOption[] | undefined => rawOptions && rawOptions.map(rawOption => ({
    ...rawOption,
    label: t(rawOption.label)
  }));

  const selectClasses = classNames(styles.bSelectBox, classes);

  return (
    <div className={selectClasses}>
      {label &&
        <label
          htmlFor={id}
          className='h3'>
          {label}
        </label>}
      <Select
        id={id}
        options={getTranslatedOptions(options)}
        className={styles.select}
        defaultValue={defaultValue}
        value={selectedOption && getTranslatedOptions([selectedOption] as SelectOption[])}
        classNamePrefix='bSelectBox'
        styles={customSelectStyles}
        placeholder={placeholder}
        onChange={onChange}
        isDisabled={disabled}
        required={required}/>
    </div>
  );
};

export default SelectBox;
