import classNames from 'classnames';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './mobileNavigation.module.scss';

interface UseMobileNavigationReturn {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  activeFirstLevel: number | null;
  mobileNavClasses: string;
  toggleMobileNav: () => void;
  toggleFirstLevel: (itemId: number) => void;
  navLinkClasses: (itemId: number) => string;
  navListAccountClasses: string;
  t: TFunction<'global', undefined>;
}

export const useMobileNavigation = (): UseMobileNavigationReturn => {
  const { t } = useTranslation('global');
  const [isOpen, setIsOpen] = useState(false);
  const [activeFirstLevel, setActiveFirstLevel] = useState<number | null>(null);

  const mobileNavClasses = classNames(styles.bMobileNavigation, {
    [styles.xOpen]: isOpen
  });

  const toggleMobileNav = (): void => {
    document.body.classList.toggle('xNoScroll', !isOpen);
    setIsOpen(!isOpen);
  };

  const toggleFirstLevel = (itemId: number): void => {
    if (activeFirstLevel === itemId) {
      setActiveFirstLevel(null);
    } else {
      setActiveFirstLevel(itemId);
    }
  };

  const navLinkClasses = (itemId: number): string => classNames(styles.navLink, {
    [styles.xActive]: activeFirstLevel === itemId
  });

  const navListAccountClasses = classNames(styles.navList, styles.xAccount);

  return {
    isOpen,
    setIsOpen,
    activeFirstLevel,
    mobileNavClasses,
    toggleMobileNav,
    toggleFirstLevel,
    navLinkClasses,
    navListAccountClasses,
    t
  };
};
