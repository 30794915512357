import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedNumber } from 'react-intl';
import { Price } from 'types/order';
import styles from './pricesList.module.scss';

interface PricesListProps {
  amountWithoutTax?: Price;
  amount?: Price;
  deliveryCosts?: Price;
  total?: Price;
}

interface PricesListItemProps {
  titleKey: string;
  value?: string;
  currencyCode?: string;
}

const PricesList = ({ amountWithoutTax, amount, deliveryCosts, total }: PricesListProps): JSX.Element => {
  const { t } = useTranslation('global');

  const PriceListItem = ({ titleKey, value, currencyCode }: PricesListItemProps): JSX.Element => (
    <>
      <dt className={styles.priceTitle}>{t(titleKey)}</dt>
      <dd className={styles.priceValue}>
        <FormattedNumber
          value={Number(value)}
          style='currency'
          currency={currencyCode || 'EUR'}/>
      </dd>
    </>
  );

  return (
    <dl className={styles.bPricesList}>
      <PriceListItem titleKey="orders.priceWithoutVat" {...amountWithoutTax} />
      <PriceListItem titleKey="orders.priceWithVat" {...amount} />
      <PriceListItem titleKey="orders.shippingPrice" {...deliveryCosts} />
      <PriceListItem titleKey="orders.total" {...total} />
    </dl>
  );
};

export default PricesList;
