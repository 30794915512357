import { TFunction } from 'i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { Dispatch, useEffect, useState, SetStateAction, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetAddresses } from 'services/getAddresses/getAddresses';
import { useGetCart } from 'services/getCart/getCart';
import { useGetPaymentMethods } from 'services/getPaymentMethods/getPaymentMethods';
import { useShippingMethods } from 'services/getShippingMethods/getShippingMethods';
import { useUpdateCart } from 'services/updateCart/updateCart';
import { cartIdAtom, cartItemsQuantityAtom, modalAtom, pageTitleAtom } from 'store/store';
import { Cart, CartItemType } from 'types/cart';
import { Method } from 'types/checkout';
import { ProductAttribute } from 'types/products';
import { useLocationParams } from 'utils/navigation';
import { isEmpty, isNotEmpty, isNotUndefined, isUndefined } from 'utils/validation';
import { ROUTE_PATHS } from 'constants/paths';
import { hasAccessToken } from 'constants/requests';

interface UseCheckoutOverviewReturn {
  t: TFunction<'global', undefined>;
  isLoading: boolean;
  checkoutItems?: CartItemType[];
  cart?: Cart;
  addressOptions?: ProductAttribute['values'];
  addressId: string;
  addressPaymentId: string;
  shippingMethods?: Method[];
  shippingMethod: string;
  paymentMethods?: Method[];
  paymentMethod: string;
  handleSummaryClick: () => void;
  handleCartClick: () => void;
  isSameAsShipping: boolean;
  isFailedOrder: boolean;
  isDisabledSummaryButton: boolean;
  handleCheckBoxChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setAddressPaymentId: Dispatch<SetStateAction<string>>;
  setPaymentMethod: Dispatch<SetStateAction<string>>;
  setShippingMethod: Dispatch<SetStateAction<string>>;
  setAddressId: Dispatch<SetStateAction<string>>;
  setModalOpenName: Dispatch<SetStateAction<string>>;
}

export const useCheckoutOverview = (): UseCheckoutOverviewReturn => {
  const { t, i18n } = useTranslation('global');
  const navigate = useNavigate();
  const cartItemsQuantity = useAtomValue(cartItemsQuantityAtom);

  useEffect(() => {
    if (!hasAccessToken()) {
      navigate(`/${ROUTE_PATHS.login}`);
      return;
    }
  }, [navigate]);

  useEffect(() => {
    if (isNotUndefined(cartItemsQuantity) && cartItemsQuantity === 0) {
      navigate(`/${ROUTE_PATHS.cart}?error=empty-cart`);
      return;
    }
  }, [cartItemsQuantity, navigate]);

  const setModalOpenName = useSetAtom(modalAtom);

  const cartId = useAtomValue(cartIdAtom);
  const { cart, isLoadingCart } = useGetCart(cartId);
  const { updateCart, isUpdatingCart } = useUpdateCart();
  const [isInitialRender, setIsInitialRender] = useState(true);

  const { addresses, isLoadingAddresses } = useGetAddresses();
  const [addressId, setAddressId] = useState('');
  const [countryId, setCountryId] = useState('');
  const [addressPaymentId, setAddressPaymentId] = useState('');

  const { shippingMethods } = useShippingMethods(countryId);
  const [shippingMethod, setShippingMethod] = useState('');

  const { paymentMethods } = useGetPaymentMethods();
  const [paymentMethod, setPaymentMethod] = useState('');

  const [isSameAsShipping, setIsSameAsShipping] = useState(true);

  const errorParam = useLocationParams('error');
  const [isFailedOrder, setIsFailedOrder] = useState(false);

  const setPageTitle = useSetAtom(pageTitleAtom);
  const metaTitle = `${t('checkout.title')} | ${t('pageTitle')} ${t('pageTitleSub')}`;

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsInitialRender(false);
  }, []);

  useEffect(() => {
    setPageTitle(metaTitle);
  }, [metaTitle, setPageTitle]);

  useEffect(() => {
    setIsFailedOrder(Boolean(errorParam));
  }, [errorParam]);

  // filter when out of stock
  const checkoutItems = cart?.items.filter(item => !item.outOfStock);

  const addressOptions = addresses?.map(address => ({
    id: address.id,
    name: `${address.firstName} ${address.lastName}${address.company ? `, ${address.company}` : ''}`,
    description: `${address.street} ${address.houseNumber} ${address.houseNumberInfo}, ${address.postalCode} ${address.city}, ${address.country.name}, ${address.email}`
  }));

  useEffect(() => {
    if (isNotEmpty(cart?.paymentAddress.id) && cart?.paymentAddress.id !== cart?.deliveryAddress.id) {
      setIsSameAsShipping(false);
    }
  }, [cart?.deliveryAddress.id, cart?.paymentAddress.id]);

  // set countryId when addressId is set
  useEffect(() => {
    if (isNotEmpty(addressId)) {
      setShippingMethod('');
      const address = addresses?.find(address => address.id === Number(addressId));

      if (address) {
        setCountryId(address.country.id.toString());
      }
    }
  }, [addressId, addresses]);

  // set addressId when only one address is available
  useEffect(() => {
    if (addresses?.length === 1 && isEmpty(cart?.deliveryAddress.id) && addresses[0].id) {
      setAddressId(addresses[0].id.toString());
    }
  }, [addresses, addressId, cart?.deliveryAddress.id]);

  useEffect(() => {
    if (isNotEmpty(cart?.deliveryAddress.id) && isEmpty(addressId)) {
      setAddressId(cart?.deliveryAddress.id?.toString() || '');
      if (isSameAsShipping) {
        setAddressPaymentId(cart?.deliveryAddress.id?.toString() || '');
      }
    }

    // changing already set address
    if ((!isInitialRender || cart?.deliveryAddress.id) && isNotEmpty(addressId) && addressId !== cart?.deliveryAddress.id?.toString()) {
      updateCart({
        language: i18n.language,
        deliveryAddressId: Number(addressId),
        ...isSameAsShipping && {
          paymentAddressId: Number(addressId)
        }
      });
      if (isSameAsShipping) {
        setAddressPaymentId(addressId);
      }
    }
  }, [addressId, cart, i18n.language, isInitialRender, isSameAsShipping, updateCart]);

  useEffect(() => {
    if (isNotEmpty(cart?.paymentAddress.id) && isEmpty(addressPaymentId)) {
      setAddressPaymentId(cart?.paymentAddress.id?.toString() || '');
    }

    if ((!isInitialRender && !isSameAsShipping) &&
      isNotEmpty(addressPaymentId) && addressPaymentId !== cart?.paymentAddress.id?.toString()) {
      updateCart({
        language: i18n.language,
        paymentAddressId: Number(addressPaymentId)
      });
    }
  }, [addressPaymentId, cart, i18n.language, isInitialRender, isSameAsShipping, updateCart]);

  useEffect(() => {
    // if only one shipping method is available
    if (isEmpty(shippingMethod) && shippingMethods?.length === 1) {
      setShippingMethod(shippingMethods[0].id.toString());
    }

    if ((!isInitialRender || cart?.deliveryType.id) && isNotEmpty(shippingMethod) && shippingMethod !== cart?.deliveryType.id?.toString()) {
      updateCart({
        language: i18n.language,
        deliveryTypeId: Number(shippingMethod)
      });
    }
  }, [shippingMethod, cart, i18n.language, updateCart, isInitialRender, shippingMethods]);

  useEffect(() => {
    if (isNotEmpty(cart?.paymentType.id) && isEmpty(paymentMethod)) {
      setPaymentMethod(cart?.paymentType.id?.toString() || '');
    }

    if ((!isInitialRender || cart?.deliveryType.id) && isNotEmpty(paymentMethod) && paymentMethod !== cart?.paymentType.id?.toString()) {
      updateCart({
        language: i18n.language,
        paymentTypeId: Number(paymentMethod)
      });
    }
  }, [paymentMethod, i18n.language, updateCart, cart, isInitialRender]);

  const handleSummaryClick = (): void => {
    navigate(`/${ROUTE_PATHS.checkoutSummary}`);
  };

  const handleCartClick = (): void => {
    navigate(`/${ROUTE_PATHS.cart}`);
  };

  const handleCheckBoxChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setIsSameAsShipping(e.target.checked);

    if (e.target.checked && (isNotEmpty(addressPaymentId) && addressPaymentId !== addressId)) {
      updateCart({
        language: i18n.language,
        paymentAddressId: Number(addressId)
      });
      setAddressPaymentId(addressId);
    }
  };

  const isDisabledSummaryButton =
    isLoadingCart ||
    isUpdatingCart ||
    isLoadingAddresses ||
    !paymentMethod ||
    !shippingMethod ||
    !addressId ||
    isUndefined(cartItemsQuantity) ||
    cartItemsQuantity === 0;

  return {
    t,
    cart,
    addressOptions,
    addressId,
    setAddressId,
    addressPaymentId,
    setAddressPaymentId,
    shippingMethods,
    shippingMethod,
    setShippingMethod,
    paymentMethods,
    paymentMethod,
    setPaymentMethod,
    isLoading: isLoadingCart || isLoadingAddresses,
    checkoutItems,
    setModalOpenName,
    isSameAsShipping,
    isFailedOrder,
    isDisabledSummaryButton,
    handleCheckBoxChange,
    handleSummaryClick,
    handleCartClick
  };
};
