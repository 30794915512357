import { FormikHelpers } from 'formik';
import { TFunction } from 'i18next';
import { useSetAtom } from 'jotai';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddUser } from 'services/updateUser/addUser';
import { pageTitleAtom } from 'store/store';
import { FormValuesRegister } from 'types/forms';
import { NetworkErrorResponse } from 'types/requests';
import { useHandleErrors } from 'utils/handleErrors';

interface UseRegisterReturn {
  t: TFunction<'global', undefined>;
  isSuccessRegister?: boolean;
  initialValues: FormValuesRegister;
  setFieldErrorRef: MutableRefObject<(field: string, message: string) => (void | null)>;
  handleSubmit: (values: FormValuesRegister, { setSubmitting }: FormikHelpers<FormValuesRegister>) => void;
}

export const useRegister = (): UseRegisterReturn => {
  const { t } = useTranslation('global');
  const [isSuccessRegister, setIsSuccessRegister] = useState<boolean>(false);

  const { addUser, isSuccess, data } = useAddUser() as {
    addUser: (newUser: FormValuesRegister) => void;
    isSuccess: boolean;
    data: NetworkErrorResponse;
  };
  const setFieldErrorRef = useRef<(field: string, message: string) => void | null>(null) as MutableRefObject<(field: string, message: string) => void | null>;

  const setPageTitle = useSetAtom(pageTitleAtom);
  const metaTitle = `${t('login.register')} | ${t('pageTitle')} ${t('pageTitleSub')}`;

  const initialValues: FormValuesRegister = {
    firstName: '',
    lastName: '',
    checkTerms: true,
    email: '',
    password: ''
  };

  const handleSubmit = (values: FormValuesRegister, { setSubmitting }: FormikHelpers<FormValuesRegister>): void => {
    addUser(values);
    setSubmitting(false);
  };

  useEffect(() => {
    setPageTitle(metaTitle);
  }, [metaTitle, setPageTitle]);

  useEffect(() => {
    if (isSuccess && data?.id) {
      setIsSuccessRegister(true);
    }
  }, [data?.id, isSuccess]);

  useHandleErrors({
    isSuccess,
    data,
    setFieldErrorRef,
    t
  });

  return {
    t,
    isSuccessRegister,
    initialValues,
    setFieldErrorRef,
    handleSubmit
  };
};
