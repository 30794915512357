import { BASE_PATH, ROUTE_PATHS } from 'constants/paths';

export const redirectAuthenticated = async (previousUrlStored?: string): Promise<void> => {
  try {
    const getPreviousUrl = (): string | undefined =>
      document.referrer.includes(BASE_PATH) &&
      !document.referrer.includes('logout') &&
      !document.referrer.includes('login') ?
        document.referrer : undefined;
    const previousUrl = getPreviousUrl() || previousUrlStored || undefined;

    if (previousUrl && previousUrl.includes(ROUTE_PATHS.cart)) {
      window.location.href = ROUTE_PATHS.checkout;
    } else if (previousUrl) {
      window.location.href = previousUrl;
    } else {
      window.location.href = ROUTE_PATHS.accountData;
    }
  } catch {
    return;
  }
};
