import { JSX } from 'react';
import { ListProduct } from 'types/products';
import ListItem from '../ListItem/ListItem';
import styles from './listing.module.scss';

interface ListingProps {
  listItems?: ListProduct[];
  title?: string;
  maxItems?: number;
}

const Listing = ({ listItems, maxItems, title }: ListingProps): JSX.Element => {
  const slicedItems = maxItems ? listItems && listItems.slice(0, maxItems) : listItems;
  
  return (
    <article className={styles.bListing}>
      {title &&
        <h1 className="h2">{title}</h1>}
      <ul className={styles.itemList}>
        {slicedItems && slicedItems.map(item =>
          <ListItem
            key={item.id}
            item={item} />
        )}
      </ul>
    </article>
  );
};

export default Listing;
