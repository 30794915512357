import emailLogo from 'assets/images/email_logo.png';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { NavigationItem } from 'types/navigation';
import { useViewport } from 'utils/useViewport';
import { ROUTE_PATHS } from 'constants/paths';
import VIEWPORTS from 'constants/viewports';
import MobileNavigation from '../_navi/MobileNavigation/MobileNavigation';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import QuickLinks from '../QuickLinks/QuickLinks';
import styles from './header.module.scss';

interface HeaderProps {
  categories?: NavigationItem[];
}

const Header = ({ categories }: HeaderProps): JSX.Element => {
  const viewport = useViewport();
  const { t } = useTranslation('global');
  const pageTitle = `${t('pageTitle')} ${t('pageTitleSub')}`;

  return (
    <header className={styles.bHeader}>
      {viewport !== VIEWPORTS.DESKTOP &&
        <MobileNavigation categories={categories} />}
      {viewport === VIEWPORTS.DESKTOP &&
        <LanguagePicker extensionClass='xHeader' />}

      <h1 className={styles.logo} aria-label={pageTitle}>
        <img src={emailLogo} alt={pageTitle} className='visuallyhidden' />
        <Link className={styles.logoLink} to={ROUTE_PATHS.home}>
          <span className={styles.headline}>{t('pageTitle')}</span>
          <span className={styles.headlineSub}>{t('pageTitleSub')}</span>
        </Link>
      </h1>

      <QuickLinks />
    </header>
  );
};

export default Header;
