import classNames from 'classnames';
import { JSX } from 'react';
import { IconNames } from 'types/icons';

interface IconProps {
  iconName: IconNames;
  iconTitle?: string;
  extensionClass?: string;
}

const Icon = ({ iconName, iconTitle, extensionClass }: IconProps): JSX.Element => {
  const iconClasses = classNames('ico', `ico-${iconName}`, extensionClass);

  return (
    <span
      role='img'
      className={iconClasses}
      aria-hidden={!iconTitle}
      aria-label={iconTitle}></span>
  );
};

export default Icon;
