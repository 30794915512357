import i18next from 'assets/config/i18n';
import { toast } from 'react-toastify';
import { HTTPMutationMethods } from 'types/requests';
import { API_URL, HEADERS, resetAccessToken } from 'constants/requests';

export const queryFetcher = <T>(url: string, signal?: AbortSignal): Promise<T> => {
  const queryLanguage = `lang=${i18next.language}`;

  return (
    fetch(`${API_URL}/${url}${queryLanguage}`, {
      headers: HEADERS,
      credentials: 'include',
      signal
    })
      .then(async (response: Response) => {
        // we need to return early for status code 204 because the function .json() below will fail with an error
        if (response.status === 204) return { success: true };

        const jsonResponse = await response.json();

        if (!response.ok) {
          if (response.status === 401) {
            resetAccessToken();
          }

          toast(jsonResponse.error?.message || jsonResponse.detail);
          throw new Error(jsonResponse.detail);
        }
        return jsonResponse;
      })
      .catch((error) => {
        toast(error.title || error.detail);
      })
  );
};

export const queryMutation = (url: string, method: HTTPMutationMethods, data?: unknown): Promise<Response> => {
  const queryLanguage = `lang=${i18next.language}`;

  return (
    fetch(`${API_URL}/${url}${queryLanguage}`, {
      method,
      body: JSON.stringify(data),
      credentials: 'include',
      headers: HEADERS
    })
      .then(async (response: Response) => {
        if (response.status === 204) return { success: true };

        const jsonResponse = await response.json();

        if (!response.ok) {
          if (response.status === 401) {
            resetAccessToken();
          }

          toast(jsonResponse.error?.message || jsonResponse.detail);
          return jsonResponse;
        }

        return jsonResponse;
      })
      .catch((error) => {
        toast(error.title || error.detail);
      })
  );
};
