import { UseMutateFunction, useQueryClient } from '@tanstack/react-query';
import { FormikHelpers } from 'formik';
import { TFunction } from 'i18next';
import { useSetAtom } from 'jotai';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetUser } from 'services/getUser/getUser';
import { useUpdateUser } from 'services/updateUser/updateUser';
import { pageTitleAtom } from 'store/store';
import { FormValuesAccount } from 'types/forms';
import { NetworkErrorResponse } from 'types/requests';
import { useHandleErrors } from 'utils/handleErrors';
import { isNotEmpty, isUndefined, objectIsNotEmpty } from 'utils/validation';
import { ROUTE_PATHS } from 'constants/paths';

interface UseAccountDataReturn {
  t: TFunction<'global', undefined>;
  isEmailReadOnly: boolean;
  initialValues: FormValuesAccount;
  setFieldErrorRef: MutableRefObject<(field: string, message: string) => (void | null)>;
  handleSubmit: (values: FormValuesAccount, { setSubmitting }: FormikHelpers<FormValuesAccount>) => void;
}

interface UpdatedValues {
  [key: string]: string | boolean | undefined;
}

export const useAccountData = (): UseAccountDataReturn => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const setPageTitle = useSetAtom(pageTitleAtom);
  const setFieldErrorRef = useRef<(field: string, message: string) => void | null>(null) as MutableRefObject<(field: string, message: string) => void | null>;
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);

  const { user } = useGetUser();
  const { updateUser, isSuccess, isPending, data } = useUpdateUser() as {
    updateUser: (updatedUser: Partial<FormValuesAccount>) => UseMutateFunction<Response, Error, FormValuesAccount, unknown>;
    isSuccess: boolean;
    isPending: boolean;
    data: NetworkErrorResponse;
  };

  const [isEmailReadOnly, setIsEmailReadOnly] = useState(false);
  const [initialValues, setInitialValues] = useState<FormValuesAccount>({
    firstName: '',
    lastName: '',
    email: '',
    newsletterActive: false,
    oldPassword: '',
    password: '',
    passwordConfirmation: ''
  });

  useEffect(() => {
    setPageTitle(t('login.accountData'));
  }, [setPageTitle, t]);

  useEffect(() => {
    if (objectIsNotEmpty(user)) {
      setInitialValues({
        ...initialValues,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        newsletterActive: user?.newsletterActive
      });

      if (isNotEmpty(user?.email)) {
        setIsEmailReadOnly(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSubmit = (values: FormValuesAccount, { setSubmitting }: FormikHelpers<FormValuesAccount>): void => {
    const updatedValues: UpdatedValues = {};

    for (const key in values) {
      if (values[key as keyof FormValuesAccount] !== initialValues[key as keyof FormValuesAccount]) {
        updatedValues[key] = values[key as keyof FormValuesAccount];

        if (key === 'passwordConfirmation') {
          setIsPasswordChanged(true);
        }
      }
    }
    // only sent changed fields
    updateUser(updatedValues);

    setTimeout(() => {
      setSubmitting(false);
      setIsPasswordChanged(false);
    }, 2000);
  };

  useHandleErrors({
    isSuccess,
    data,
    setFieldErrorRef,
    t
  });

  useEffect(() => {
    if (!isPending && isSuccess && isUndefined(data?.invalidParams) && !isPasswordChanged) {
      queryClient.invalidateQueries({ queryKey: ['getUser'] });
    }
  }, [data?.invalidParams, isPasswordChanged, isPending, isSuccess, queryClient]);

  useEffect(() => {
    if (!isPending && isPasswordChanged && isUndefined(data?.invalidParams)) {
      toast(t('login.passwordChangedSuccess'));
      return navigate(`/${ROUTE_PATHS.logout}`);
    }
  }, [data?.invalidParams, isPasswordChanged, isPending, navigate, t]);

  return {
    t,
    isEmailReadOnly,
    initialValues,
    setFieldErrorRef,
    handleSubmit
  };
};
