import { useQuery } from '@tanstack/react-query';
import { GetPaymentMethods } from 'types/requests';
import { fetchPaymentMethods } from 'utils/fetch';
import { hasAccessToken } from 'constants/requests';

export const useGetPaymentMethods = (): GetPaymentMethods => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ['getPaymentMethods'],
    queryFn: () => fetchPaymentMethods(),
    enabled: hasAccessToken()
  });

  return {
    paymentMethods: data,
    isLoading,
    isError
  };
};
