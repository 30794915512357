import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Location, useLocation } from 'react-router-dom';

interface UseNavigationReturn {
  t: TFunction<'global', undefined>;
  location: Location<unknown>;
}

export const useNavigation = (): UseNavigationReturn => {
  const { t } = useTranslation('global');
  const location = useLocation();

  return {
    t,
    location
  };
};
