import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import i18next from 'i18next';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import Modal from 'react-modal';
import { RouterProvider } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import './assets/config/i18n';
import './assets/styles/basics.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1
    }
  }
});

Modal.setAppElement('#root');

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18next}>
        <HelmetProvider>
          <RouterProvider router={ROUTES} />
        </HelmetProvider>
      </I18nextProvider>
    </QueryClientProvider>
  </StrictMode>
);
