import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import Loading from 'components/Loading/Loading';
import { JSX } from 'react';
import { isEmpty } from 'utils/validation';
import styles from './checkoutPayment.module.scss';
import { useCheckoutPayment } from './useCheckoutPayment';

const CheckoutPayment = (): JSX.Element => {
  const { options, stripePromise } = useCheckoutPayment();

  return (
    <div className={styles.bCheckoutPayment}>
      {isEmpty(options.clientSecret) ?
        <Loading height={100}/> :
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={options}>
          <EmbeddedCheckout/>
        </EmbeddedCheckoutProvider>}
    </div>
  );
};

export default CheckoutPayment;
