import Icon from 'components/Icon/Icon';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from 'constants/paths';

interface NavItemStoreProps {
  navButtonClasses?: (slug: string) => string;
  hasIcon?: boolean;
  extendOnClick?(): void;
}

const NavItemStore = ({ navButtonClasses, hasIcon, extendOnClick }: NavItemStoreProps): JSX.Element => {
  const { t } = useTranslation('global');

  return (
    <li className='navItem'>
      <Link
        to={ROUTE_PATHS.home}
        className={navButtonClasses ? navButtonClasses('') : 'navLink'}
        onClick={extendOnClick}>
        {t('navigation.store')}
      </Link>
      {hasIcon && <Icon iconName="chevron-right"/>}
    </li>
  );
};

export default NavItemStore;
