import classNames from 'classnames';
import { Dispatch, JSX, SetStateAction } from 'react';
import { ProductAttribute } from 'types/products';
import styles from './radioSelect.module.scss';

interface RadioSelectProps {
  options: ProductAttribute['values'];
  setValue: Dispatch<SetStateAction<string>>;
  radioName: string;
  value?: string;
  title?: string;
  extensionClass?: string;
}

const RadioSelect = ({ options, title, setValue, value, extensionClass, radioName }: RadioSelectProps): JSX.Element => {
  const radioId = (radioOption: ProductAttribute['values'][0]): string =>
    `radio-${radioOption.name}-${radioOption.id}-${radioName}`.toLowerCase();
  const radioSelectClasses = classNames(styles.bRadioSelect, extensionClass);

  return (
    <div className={radioSelectClasses}>
      {title &&
        <h2 className='h3'>{title}</h2>}
      <div className={styles.radiosWrapper}>
        {options.map((option) => {
          const descriptionHasHTML = option.description && option.description.includes('<');

          return (
            <div key={option.id} className={styles.radioWrapper}>
              <input
                type="radio"
                id={radioId(option)}
                name={radioName}
                value={option.id}
                className={styles.radioInput}
                checked={option.id === Number(value)}
                disabled={option.outOfStock} // add put of stock from external
                onChange={(event): void => setValue(event.target.value)}/>
              <label
                htmlFor={radioId(option)}
                className={styles.radioLabel}>
                <span className={styles.imgNameWrapper}>
                  {option.imgSrc &&
                    <img src={option.imgSrc} alt={option.name} className={styles.labelImg}/>}
                  {option.name}
                </span>
                {option.description && !descriptionHasHTML &&
                  <span className={styles.description}>{option.description}</span>}
                {option.description && descriptionHasHTML &&
                  <span className={styles.description} dangerouslySetInnerHTML={{ __html: option.description }}/>}
              </label>
            </div>);
        })}
      </div>
    </div>
  );
};

export default RadioSelect;
