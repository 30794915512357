import { useQuery } from '@tanstack/react-query';
import { FilterOptions, GetProducts } from 'types/requests';
import { fetchProducts } from 'utils/fetch';
import { URL_PRODUCT_PREFIX } from 'constants/paths';

interface UseGetProductsProps {
  category?: string;
  createdAt?: FilterOptions;
  name?: FilterOptions;
}

export const useGetProducts = ({ category, createdAt, name }: UseGetProductsProps): GetProducts => {
  const queryCategory = category ? `category=${category}&` : '';
  const querySortCreatedAt = createdAt ? `order[createdAt]=${createdAt}&` : '';
  const querySortName = name ? `order[name]=${name}&` : '';

  const optionsQuery = `${queryCategory}${querySortCreatedAt}${querySortName}`;
  
  const { data, isError, isLoading } = useQuery({
    queryKey: ['getProducts', category, name, createdAt],
    queryFn: () => fetchProducts(optionsQuery),
    throwOnError: false,
    staleTime: Infinity // keep data cached
  });

  const updatedData = isError ? [] : data?.map(product => ({
    ...product,
    slug: `/${URL_PRODUCT_PREFIX}/${product.id}-${product.slug}`
  }));

  return {
    isLoading,
    products: updatedData,
    isError
  };
};
