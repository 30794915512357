import classNames from 'classnames';
import Button from 'components/Button/Button';
import SelectBox from 'components/SelectBox/SelectBox';
import { Formik, Field, Form, ErrorMessage, FieldInputProps, FormikProps } from 'formik';
import { E164Number } from 'libphonenumber-js';
import { JSX } from 'react';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { renderRequiredPlaceholder } from 'utils/forms';
import { validationEditAddress } from 'constants/validation';
import styles from './editAddress.module.scss';
import { useEditAddress } from './useEditAddress';

interface EditAddressProps {
  isCheckout?: boolean;
  isNew?: boolean;
}

const EditAddress = ({ isCheckout }: EditAddressProps): JSX.Element => {
  const {
    t,
    pageTitle,
    isNewAddress,
    initialValues,
    countriesOptions,
    defaultCountryOption,
    isLoadingCountries,
    handleSubmit,
    navigateToAddresses,
    handleCountryChange,
    cancelModal
  } = useEditAddress(isCheckout);

  const editAddressClasses = classNames(styles.bEditAddress, {
    'xCheckout': isCheckout
  });

  return (
    <article className={editAddressClasses}>
      <h1 className='h3'>{isCheckout ? t('address.addAddress') : pageTitle}</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationEditAddress(t)}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnMount={true}>
        {({ isSubmitting, isValid, dirty, setFieldValue }) => {
          return (
            <Form className='form xWide'>
              <Field
                className='input'
                type='text'
                name='firstName'
                placeholder={renderRequiredPlaceholder('address.firstName', t)}
                autoComplete='given-name'
                required/>
              <ErrorMessage
                name='firstName'
                component='p'
                className='inlineError'/>

              <Field
                className='input'
                type='text'
                name='lastName'
                placeholder={renderRequiredPlaceholder('address.lastName', t)}
                autoComplete='family-name'
                required />
              <ErrorMessage
                name='lastName'
                component='p'
                className='inlineError'/>

              <Field
                className='input'
                type='text'
                name='company'
                placeholder={t('address.company')}
                autoComplete='organization'/>
              <ErrorMessage
                name='company'
                component='p'
                className='inlineError'/>

              <div className='oneLineWrapper'>
                <Field
                  className='input'
                  type='text'
                  name='street'
                  placeholder={renderRequiredPlaceholder('address.address1', t)}
                  autoComplete='address-line1'
                  required/>
                <Field
                  className='input xShort'
                  type='text'
                  name='houseNumber'
                  placeholder={renderRequiredPlaceholder('address.address1Number', t)}
                  autoComplete='address-line2'
                  required/>
                <ErrorMessage
                  name='street'
                  component='p'
                  className='inlineError'/>
                <ErrorMessage
                  name='houseNumber'
                  component='p'
                  className='inlineError'/>
              </div>

              <Field
                className='input'
                type='text'
                name='houseNumberInfo'
                placeholder={t('address.address2')}
                autoComplete='address-line2'/>
              <ErrorMessage
                name='houseNumberInfo'
                component='p'
                className='inlineError'/>

              <Field
                className='input'
                type='text'
                name='city'
                placeholder={renderRequiredPlaceholder('address.city', t)}
                autoComplete='address-level2'
                required/>
              <ErrorMessage
                name='city'
                component='p'
                className='inlineError'/>

              <Field
                className='input'
                type='text'
                name='postalCode'
                placeholder={renderRequiredPlaceholder('address.zipCode', t)}
                autoComplete='postal-code'
                required/>
              <ErrorMessage
                name='postalCode'
                component='p'
                className='inlineError'/>

              {!isLoadingCountries &&
              <>
                <SelectBox
                  key={defaultCountryOption?.value}
                  name='country'
                  id='country-select'
                  options={countriesOptions}
                  defaultValue={defaultCountryOption}
                  classes={styles.selectWrapper}
                  placeholder={renderRequiredPlaceholder('address.countrySelect', t)}
                  onChange={(selectedCountry: unknown) => handleCountryChange(selectedCountry, setFieldValue)}
                  required/>
                <ErrorMessage
                  name='country'
                  component='p'
                  className='inlineError'/>
              </>}

              <Field
                className='input'
                type='text'
                name='email'
                placeholder={renderRequiredPlaceholder('address.email', t)}
                autoComplete='email'
                required/>
              <ErrorMessage
                name='email'
                component='p'
                className='inlineError'/>

              <Field
                name='phone'>
                {({ field, form }: { field: FieldInputProps<E164Number>; form: FormikProps<string> }) => (
                  <PhoneInputWithCountrySelect
                    {...field}
                    rules={{ required: true }}
                    defaultCountry='SI'
                    country={defaultCountryOption?.value}
                    smartCaret={true}
                    withCountryCallingCode={true}
                    placeholder={renderRequiredPlaceholder('address.phone', t)}
                    value={field.value}
                    onChange={value => form.setFieldValue(field.name, value)}
                    autoComplete='tel'/>
                )}</Field>
              <ErrorMessage
                name='phone'
                component='p'
                className='inlineError'/>

              {!isCheckout && <>
                <Field
                  className='input'
                  type='text'
                  name='vatId'
                  placeholder={t('address.vatId')}
                  autoComplete='vat-number'/>
                <ErrorMessage
                  name='vatId'
                  component='p'
                  className='inlineError'/>
              </>}

              {(!isCheckout && !isNewAddress) &&
              <div className='checkboxWrapper'>
                <Field
                  type='checkbox'
                  name='default'
                  id='account-default'
                  className='checkbox'/>
                <label
                  htmlFor='account-default'
                  className='checkLabel'>
                  <span className='labelText'>
                    {t('address.default')}
                  </span>
                </label>
                <ErrorMessage
                  name='default'
                  component='p'
                  className='inlineError'/>
              </div>}

              <div className='buttonRowWrapper'>
                <Button
                  type='reset'
                  extensionClass='submitButton xSecondary'
                  onClick={isCheckout ? cancelModal : navigateToAddresses}>
                  {t('account.cancel')}
                </Button>
                <Button
                  type='submit'
                  disabled={isSubmitting || !isValid || !dirty}
                  extensionClass='submitButton'>
                  {t('login.save')}
                </Button>
              </div>
            </Form>
          );}}
      </Formik>
    </article>);
};

export default EditAddress;
