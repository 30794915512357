import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { Viewport } from 'types/viewports';
import { isEmpty } from './validation';
import { getViewportFromScreenSize } from './viewports';

export const useViewport = (): Viewport | '' => {
  const [viewport, setViewport] = useState<Viewport | ''>('');

  const handleViewportUpdate = (): void => {
    setViewport(getViewportFromScreenSize());
  };

  useEffect(() => {
    if (isEmpty(viewport)) {
      handleViewportUpdate();
    }

    window.addEventListener('resize', debounce(handleViewportUpdate, 100));

    return (): void => {
      window.removeEventListener('resize', handleViewportUpdate);
    };
  }, [viewport]);

  return viewport;
};
