import Button from 'components/Button/Button';
import { JSX } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { AddressSingle } from 'types/user';
import styles from './address.module.scss';
import { useAddress } from './useAddress';

interface AddressProps {
  address?: AddressSingle;
}

const Address = ({ address }: AddressProps): JSX.Element => {
  const {
    t,
    navigateToEdit,
    handleDeleteAddress
  } = useAddress(address?.id);

  const renderAddressFields = (): (JSX.Element | null)[] | undefined => {
    const fields: { label: string | undefined }[] | undefined = address && [
      { label: `${address?.firstName} ${address?.lastName}` },
      { label: address?.company },
      { label: `${address?.street} ${address?.houseNumber}` },
      { label: address?.houseNumberInfo },
      { label: `${address?.postalCode} ${address?.city}` },
      { label: address?.country.name },
      { label: address?.phone && formatPhoneNumberIntl(address?.phone) },
      { label: address?.email },
      { label: address?.vatId }
    ];

    return fields && fields.flatMap((field, index) => (
      field.label ?
        <p key={`${address?.id}-${index}`} className={styles.adressItem}>{field.label}</p> :
        null
    ));
  };

  const isDefault = address?.default;

  return (
    <article className={styles.bAddress}>
      {isDefault &&
        <h1 className='h3'>{t('account.default')}</h1>}
      <address className={styles.address}>
        {renderAddressFields()}
      </address>
      {address &&
        <div className="buttonRowWrapper">
          <Button
            extensionClass="xSecondary xSmall"
            onClick={navigateToEdit}>{t('account.edit')}</Button>
          <Button
            type="reset"
            extensionClass="xSecondary xSmall"
            disabled={isDefault}
            onClick={!isDefault ? handleDeleteAddress : undefined}>
            {t('account.delete')}
          </Button>
        </div>}
    </article>
  );
};

export default Address;
