import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddToCartRequest } from 'services/updateCart/addToCart';
import { cartIdAtom } from 'store/store';
import { AddToCart } from 'types/cart';
import { Product } from 'types/products';
import { NetworkErrorResponse } from 'types/requests';
import { SelectOption } from 'types/sorting';
import { isEmpty, isNotEmpty, objectIsNotEmpty } from 'utils/validation';

interface UseAddToCartReturn {
  setSizeId: Dispatch<SetStateAction<string>>;
  setColorId: Dispatch<SetStateAction<string>>;
  colorId: string;
  sizeId: string;
  isAdded: boolean;
  isIncomplete: boolean;
  addToCartError?: string;
  quantity: SelectOption;
  maxQuantity?: number;
  handleQuantityChange: (selectedOption?: SelectOption | unknown) => void;
  handleAddToCart: () => void;
}

interface UseAddToCartProps {
  product?: Product;
}

export const useAddToCart = ({ product }: UseAddToCartProps): UseAddToCartReturn => {
  const queryClient = useQueryClient();
  const { i18n } = useTranslation('global');
  const [sizeId, setSizeId] = useState('');
  const [colorId, setColorId] = useState('');
  const [quantity, setQuantity] = useState({ value: '1', label: '1' });
  const [maxQuantity, setMaxQuantity] = useState<number | undefined>();
  const [addToCartError, setAddToCartError] = useState('');

  const [isAdded, setIsAdded] = useState(false);
  const [isIncomplete, setIsIncomplete] = useState(true);

  const cartId = useAtomValue(cartIdAtom);

  const sizeAttribute = product?.attributes.find(attribute => attribute.id === 1);
  const colorAttribute = product?.attributes.find(attribute => attribute.id === 2);
  const hasSizeAttribute = objectIsNotEmpty(sizeAttribute as Record<string, unknown> | undefined);
  const hasColorAttribute = objectIsNotEmpty(colorAttribute as Record<string, unknown> | undefined);

  const { addToCart, isError, isSuccess, data } = useAddToCartRequest() as {
    addToCart: (addToCart: AddToCart) => void;
    isSuccess: boolean;
    isError: boolean;
    data: NetworkErrorResponse;
  };

  // use sizeId and colorId to find the units in stock
  useEffect(() => {
    if (hasSizeAttribute && hasColorAttribute) {
      const units = product?.stock.find(stock =>
        stock.attributes.includes(Number(sizeId)) &&
        stock.attributes.includes(Number(colorId)))?.units;

      setMaxQuantity(units);
    } else if (hasSizeAttribute && sizeId) {
      const units = product?.stock.find(stock =>
        stock.attributes.includes(Number(sizeId)))?.units;

      setMaxQuantity(units);
    } else if (hasColorAttribute && colorId) {
      const units = product?.stock.find(stock =>
        stock.attributes.includes(Number(colorId)))?.units;

      setMaxQuantity(units);
    } else {
      const units = product?.stock.find(stock =>
        isEmpty(stock.attributes))?.units;

      setMaxQuantity(units);
    }
  }, [colorId, hasColorAttribute, hasSizeAttribute, product?.stock, sizeId]);

  useEffect(() => {
    if (hasSizeAttribute && sizeAttribute?.values.length === 1) {
      setSizeId((sizeAttribute?.values[0].id || '').toString());
    }
    if (hasColorAttribute && colorAttribute?.values.length === 1) {
      setColorId((colorAttribute?.values[0].id || '').toString());
    }
  }, [colorAttribute?.values, hasColorAttribute, hasSizeAttribute, sizeAttribute?.values]);

  useEffect(() => {
    if (hasSizeAttribute && hasColorAttribute) {
      setIsIncomplete(!(sizeId && colorId));
    } else if (hasSizeAttribute) {
      setIsIncomplete(!sizeId);
    } else if (hasColorAttribute) {
      setIsIncomplete(!colorId);
    } else {
      setIsIncomplete(false);
    }
  } , [colorId, hasColorAttribute, hasSizeAttribute, sizeId]);

  const handleQuantityChange = (selectedOption?: SelectOption | unknown): void => {
    if (selectedOption) {
      setQuantity((selectedOption as SelectOption));
    }
  };

  // set quantity to max quantity if selected quantity is more than max quantity
  useEffect(() => {
    if (maxQuantity && Number(quantity.value) > maxQuantity) {
      setQuantity({ value: maxQuantity.toString(), label: maxQuantity.toString() });
    }
  }, [maxQuantity, quantity.value]);

  const handleAddToCart = (): void => {
    const attributeIds = [sizeId, colorId].filter(Boolean).map(Number);

    const addProduct: AddToCart['items'] = [{
      quantity: Number(quantity.value),
      productId: product?.id,
      attributeIds
    }];

    addToCart({
      id: cartId,
      language: i18n.language,
      items: addProduct
    });

    setIsAdded(true);
    if (isError) setIsAdded(false);

    setTimeout(() => {
      setIsAdded(false);
    }, 3000);
  };

  useEffect(() => {
    if (isSuccess && isNotEmpty(data?.invalidParams)) {
      setAddToCartError(data.invalidParams[0].key);
    } else if (isSuccess) {
      queryClient.invalidateQueries({ queryKey: ['getProduct'] });
    } else {
      setAddToCartError('');
    }
  }, [data, data?.invalidParams, isSuccess, queryClient]);

  return {
    setSizeId,
    setColorId,
    colorId,
    sizeId,
    isAdded,
    isIncomplete,
    addToCartError,
    quantity,
    maxQuantity,
    handleQuantityChange,
    handleAddToCart
  };
};
