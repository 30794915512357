import { JSX } from 'react';
import { Link } from 'react-router-dom';
import { IconNames } from 'types/icons';
import { NAVIGATION_QUICK } from 'constants/navigation';
import Icon from '../Icon/Icon';
import Search from '../Search/Search';
import styles from './quickLinks.module.scss';
import { useQuickLinks } from './useQuickLinks';

interface QuickLinksProps {
  isMobileNav?: boolean;
  isMobileSearchClosed?: boolean;
}

const QuickLinks = ({ isMobileNav, isMobileSearchClosed }: QuickLinksProps): JSX.Element => {
  const {
    quickLinksClasses,
    isOpen,
    setIsOpen,
    handleButtonClick,
    t,
    viewport,
    cartItemsQuantity
  } = useQuickLinks({ isMobileNav, isMobileSearchClosed });

  return (
    <>
      <Search isOpen={isOpen} setIsOpen={setIsOpen} isMobileNav={isMobileNav} />
      <ul className={quickLinksClasses}>
        {NAVIGATION_QUICK({ viewport, isMobileNav }).map(item => (
          <li key={item.id} className={styles.listItem}>
            {item.slug ?
              <Link to={item.slug} className={styles.link} aria-label={t(item.name)}>
                <Icon iconName={item.icon as IconNames} iconTitle={t(item.name)} extensionClass={styles.listIcon} />
                {item.slug === 'cart' && cartItemsQuantity > 0 &&
                  <span key={cartItemsQuantity} className={styles.cartItemsBubble}>{cartItemsQuantity}</span>}
              </Link> :
              <button className={styles.link} type='button' onClick={handleButtonClick(item.name)}>
                <Icon iconName={item.icon as IconNames} iconTitle={t(item.name)} extensionClass={styles.listIcon} />
              </button>}
          </li>))}
      </ul>
    </>
  );
};

export default QuickLinks;
