import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { queryMutation } from 'services/services';
import { FormValuesAddress } from 'types/forms';
import { GenericMutationResponse } from 'types/requests';
import { hasAccessToken } from 'constants/requests';

interface UseAddAddressReturn extends GenericMutationResponse<FormValuesAddress> {
  addAddresses: UseMutateFunction<Response, Error, FormValuesAddress, unknown>;
}

export const useAddAddress = (): UseAddAddressReturn => {
  const queryClient = useQueryClient();
  const { mutate, ...args } = useMutation({
    mutationFn: (newAddress: FormValuesAddress) => {
      if (hasAccessToken()) {
        return queryMutation('user/addresses?', 'POST', newAddress);
      }
      return Promise.reject('No access token');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAddresses'] });
    }
  });

  return {
    addAddresses: mutate,
    ...args
  };
};
