import classNames from 'classnames';
import styles from 'components/Search/search.module.scss';
import { TFunction } from 'i18next';
import { FormEvent, RefObject, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'constants/paths';

interface UseSearchReturn {
  t: TFunction<'global', undefined>;
  searchClasses: string;
  searchInputRef: RefObject<HTMLInputElement>;
  submitButtonRef: RefObject<HTMLButtonElement>;
  closeButtonRef: RefObject<HTMLButtonElement>;
  closeMobileNav: () => void;
  handleSearchSubmit?: (event: FormEvent<HTMLFormElement>) => void;
}

export const useSearch = (
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  isMobileNav?: boolean): UseSearchReturn => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();

  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const searchClasses = classNames(styles.bSearch, {
    [styles.xOpen]: isOpen
  });

  const closeMobileNav = (): void => {
    if (!isMobileNav) {
      document.body.classList.remove('xNoScroll');
    }
    setIsOpen(false);
  };

  const handleSearchSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    closeMobileNav();
    const searchTerm = searchInputRef?.current?.value;

    if (searchTerm) {
      navigate(`${ROUTE_PATHS.search}?q=${searchTerm}`);
    }
  };

  useEffect(() => {
    const keydownListener = (event: KeyboardEvent): void => {
      if (event.key === 'Tab') {
        if (searchInputRef?.current === event.target && !event.shiftKey) {
          submitButtonRef?.current?.focus();
          event.preventDefault();
        } else if (submitButtonRef?.current === event.target && !event.shiftKey) {
          closeButtonRef?.current?.focus();
          event.preventDefault();
        } else if (closeButtonRef?.current === event.target && !event.shiftKey) {
          searchInputRef?.current?.focus();
          event.preventDefault();
        }
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', keydownListener);
      searchInputRef?.current?.focus();
    } else {
      document.removeEventListener('keydown', keydownListener);
    }

    return (): void => {
      document.removeEventListener('keydown', keydownListener);
    };
  }, [isOpen, searchInputRef, closeButtonRef, submitButtonRef]);

  return {
    t,
    searchInputRef,
    submitButtonRef,
    closeButtonRef,
    searchClasses,
    closeMobileNav,
    handleSearchSubmit
  };
};
