import { SelectOption } from 'types/sorting';

export enum SORT_VALUES {
  ASC = 'asc',
  DESC = 'desc'
}

export const SORTING_OPTIONS: SelectOption[] = [
  {
    value: 'name-asc',
    label: 'sorting.nameAsc'
  },
  {
    value: 'name-desc',
    label: 'sorting.nameDesc'
  },
  {
    value: 'createdAt-asc',
    label: 'sorting.dateAsc'
  },
  {
    value: 'createdAt-desc',
    label: 'sorting.dateDesc'
  }
];

export const QUANTITY_OPTIONS = (maxQuantity: number = 10): SelectOption[] =>
  Array.from({ length: maxQuantity + 1 },
    (_, i) => ({
      value: `${ i }`,
      label: `${ i }`
    }));
