import Icon from 'components/Icon/Icon';
import { JSX } from 'react';
import { useParams } from 'react-router-dom';
import { Product } from 'types/products';
import { removeStringBeforeDash } from 'utils/string';
import { URL_LISTINGS_PREFIX } from 'constants/paths';
import NavItemStore from '../NavItemStore/NavItemStore';
import styles from './breadcrumb.module.scss';

interface BreadcrumbProps {
  categories?: Product['categories'];
}

const Breadcrumb = ({ categories }: BreadcrumbProps): JSX.Element => {
  const { categoryParam, slugParam } = useParams<{ categoryParam: string; slugParam: string }>();

  const categoryTitle = (category: string): string | undefined => category && category.includes('-') ?
    removeStringBeforeDash(category) : category;
  
  return (
    <ol className={styles.bBreadcrumb}>
      <NavItemStore hasIcon={true} />
      {categoryParam &&
        <li className={styles.navItem}>
          <a className={styles.navLink} href={categoryParam}>{categoryTitle(categoryParam)}</a>
          <Icon iconName="chevron-right"/>
        </li>}
      {categories &&
        categories.map((categoryItem) => {
          return (
            <li key={categoryItem.slug} className={styles.navItem}>
              <a className={styles.navLink} href={`/${URL_LISTINGS_PREFIX}/${categoryItem.slug}`}>{categoryTitle(categoryItem.name)}</a>
              <Icon iconName="chevron-right"/>
            </li>);
        })}
      {slugParam &&
        <li className={styles.navItem}>
          <span className={styles.navLink}>{slugParam}</span>
        </li>}
    </ol>
  );
};

export default Breadcrumb;
