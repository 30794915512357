import { TFunction } from 'i18next';
import { useSetAtom } from 'jotai';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetCategories } from 'services/getCategories/getCategories';
import { useGetProducts } from 'services/getProducts/getProducts';
import { pageTitleAtom } from 'store/store';
import { NavigationItem } from 'types/navigation';
import { ListProduct } from 'types/products';
import { SelectOption } from 'types/sorting';
import { replaceDashWithDot } from 'utils/string';
import { isNotEmpty } from 'utils/validation';
import { SORT_VALUES, SORTING_OPTIONS } from 'constants/sorting';

interface UseListingOverviewReturn {
  t: TFunction<'global', undefined>;
  title: string;
  isLoading?: boolean;
  isError?: boolean;
  subCategories?: NavigationItem[];
  products?: ListProduct[];
  selectedSortOption: SelectOption;
  getLastTwoPartsOfSlug: (slug: string) => string;
  handleSortChange: (selectedOption?: SelectOption | unknown) => void;
}

export const useListingOverview = (): UseListingOverviewReturn => {
  const { t } = useTranslation('global');
  const setPageTitle = useSetAtom(pageTitleAtom);
  const { categories } = useGetCategories();
  const { categoryParam } = useParams<{ categoryParam: string }>();
  const categoryFromUrl = categoryParam ? categoryParam : '';

  const [selectedSortOption, setSelectedSortOption] = useState(SORTING_OPTIONS[0]);
  const [subCategories, setSubCategories] = useState<NavigationItem[] | undefined>(undefined);

  const { products, isLoading, isError } = useGetProducts({
    category: categoryFromUrl,
    createdAt: selectedSortOption.value === 'createdAt-asc' ? SORT_VALUES.ASC :
      selectedSortOption.value === 'createdAt-desc' ? SORT_VALUES.DESC : undefined,
    name: selectedSortOption.value === 'name-asc' ? SORT_VALUES.ASC :
      selectedSortOption.value === 'name-desc' ? SORT_VALUES.DESC : undefined
  });

  const handleSortChange = (selectedOption?: SelectOption | unknown): void => {
    if (selectedOption) {
      setSelectedSortOption((selectedOption as SelectOption));
    }
  };

  const title = products && isNotEmpty(products) ?
    replaceDashWithDot(products[0].categories[0].name) : categoryFromUrl;
  const metaTitle = `${title} | ${t('pageTitle')} ${t('pageTitleSub')}`;

  const getLastTwoPartsOfSlug = (slug: string): string => {
    const parts = slug.split('/').filter(Boolean);

    return `/${parts.slice(-2).join('/')}`;
  };

  const getLastPartOfSlug = (slug: string): string => {
    const parts = slug.split('/').filter(Boolean);

    return parts[parts.length - 1];
  };
  const currentCategory = categories && categories.filter(category =>
    getLastPartOfSlug(category.slug) === categoryFromUrl)[0];

  useEffect(() => {
    setPageTitle(metaTitle);
  }, [metaTitle, setPageTitle]);

  useEffect(() => {
    if (currentCategory && (currentCategory.id === 2 ||
      currentCategory.id === 3 || currentCategory.id === 4) && !isEqual(subCategories, currentCategory.children)) {
      setSubCategories(currentCategory.children);
    } else if (currentCategory && currentCategory.id !== 2 &&
      currentCategory.id !== 3 && currentCategory.id !== 4 && subCategories) {
      setSubCategories(undefined);
    }
  }, [currentCategory, categories, categoryFromUrl, subCategories]);

  return {
    t,
    title,
    products,
    isLoading,
    isError,
    subCategories,
    selectedSortOption,
    getLastTwoPartsOfSlug,
    handleSortChange
  };
};
