import { TFunction } from 'i18next';
import { useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { stripeSecretAtom } from 'store/store';
import { UserOrder } from 'types/order';
import { Viewport } from 'types/viewports';
import { useLocationParams } from 'utils/navigation';
import { useViewport } from 'utils/useViewport';
import Viewports from 'constants/viewports';

interface UseMyOrderReturn {
  t: TFunction<'global', undefined>;
  viewport: Viewport | '';
  isDetailVisible: boolean;
  orderDate: Date;
  myOrderProps: Record<string, unknown>;
  handleDetailVisibility: () => void;
}

export const useMyOrder = (order: UserOrder): UseMyOrderReturn => {
  const { t } = useTranslation('global');
  const viewport = useViewport();
  const navigate = useNavigate();
  const location = useLocation();
  const orderParam = useLocationParams('order');
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const setStripeSecret = useSetAtom(stripeSecretAtom);

  const orderDate = new Date(order.dateTime);

  const handleDetailVisibility = (): void => {
    setIsDetailVisible(!isDetailVisible);
  };

  const myOrderProps = viewport === Viewports.MOBILE ? {
    onClick: handleDetailVisibility,
    role: 'button',
    tabIndex: 0,
    'aria-expanded': isDetailVisible,
    'aria-label': t('orders.toggleDetails'),
    title: t('orders.toggleDetails'),
    'aria-pressed': isDetailVisible
  } : {};

  // add order id to url when detail is visible
  useEffect(() => {
    const currentURL = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentURL.search);

    if (isDetailVisible && order.id) {
      searchParams.set('order', order.id.toString());
    } else {
      searchParams.delete('order');
      setStripeSecret(RESET);
    }

    navigate(`${location.pathname}?${searchParams}`, { replace: true });
  }, [isDetailVisible, location.pathname, navigate, order.id, setStripeSecret]);

  // set visible if order id is in url
  useEffect(() => {
    if (orderParam === order.id?.toString()) {
      setIsDetailVisible(true);
    }
  }, [location.search, order.id, orderParam]);

  return {
    t,
    viewport,
    isDetailVisible,
    orderDate,
    myOrderProps,
    handleDetailVisibility
  };
};
