import { TFunction } from 'i18next';
import { useAtom, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetCart } from 'services/getCart/getCart';
import { useGetPaymentSession } from 'services/getPaymentSession/getPaymentSession';
import { useAddUserOrder } from 'services/updateUserOrder/addUserOrder';
import { cartIdAtom, cartItemsQuantityAtom, pageTitleAtom, stripeSecretAtom } from 'store/store';
import { Cart, CartItemType } from 'types/cart';
import { PaymentType, UserOrder } from 'types/order';
import { NetworkErrorResponse } from 'types/requests';
import { isNotUndefined, isUndefined } from 'utils/validation';
import { ROUTE_PATHS } from 'constants/paths';
import { hasAccessToken } from 'constants/requests';

interface UseCheckoutSummaryReturn {
  t: TFunction<'global', undefined>;
  isLoadingCart: boolean;
  isDisabledBuyButton: boolean;
  isLoadingPayment: boolean;
  checkoutItems?: CartItemType[];
  cart?: Cart;
  handleCheckoutClick: () => void;
  handleBuyClick: () => void;
  handleContinueShopping: () => void;
}

export const useCheckoutSummary = (isConfirmation?: boolean): UseCheckoutSummaryReturn => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const [cartItemsQuantity, setCartItemsQuantity] = useAtom(cartItemsQuantityAtom);
  // make loading state
  const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(false);

  useEffect(() => {
    if (!hasAccessToken()) {
      navigate(`/${ROUTE_PATHS.login}`);
      return;
    }
  }, [navigate]);

  useEffect(() => {
    if (!isConfirmation && isNotUndefined(cartItemsQuantity) && cartItemsQuantity === 0) {
      navigate(`/${ROUTE_PATHS.cart}?error=empty-cart`);
      return;
    }
  }, [cartItemsQuantity, isConfirmation, navigate]);

  const [cartId, setCartId] = useAtom(cartIdAtom);
  const { cart, isLoadingCart } = useGetCart(cartId, isConfirmation);
  const { addOrder, isSuccess, isError, data, isPending } = useAddUserOrder() as {
    addOrder: (cartId: string) => void;
    isSuccess: boolean;
    isError: boolean;
    isPending: boolean;
    data: UserOrder | NetworkErrorResponse;
  };
  const { paymentSession } = useGetPaymentSession(data?.id?.toString());
  const setStripeSecret = useSetAtom(stripeSecretAtom);

  const setPageTitle = useSetAtom(pageTitleAtom);
  const metaTitle = `${t('checkout.summary')} | ${t('pageTitle')} ${t('pageTitleSub')}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPageTitle(metaTitle);
  }, [metaTitle, setPageTitle]);

  // filter when out of stock
  const checkoutItems = cart?.items.filter(item => !item.outOfStock);

  const handleCheckoutClick = (): void => {
    navigate(`/${ROUTE_PATHS.checkout}`);
  };

  const handleBuyClick = (): void => {
    if (cartId) {
      addOrder(cartId);
    }
  };

  const handleContinueShopping = (): void => {
    navigate(ROUTE_PATHS.home);
  };

  const isDisabledBuyButton = isLoadingCart ||
    isLoadingPayment ||
    isPending ||
    isUndefined(cartItemsQuantity) ||
    cartItemsQuantity === 0;

  useEffect(() => {
    if (isSuccess && 'paymentType' in data && data.paymentType.id === PaymentType.CreditCard) {
      // assume stripe payment
      setCartItemsQuantity(RESET);
      setCartId(RESET);
      setIsLoadingPayment(true);

      if (isNotUndefined(paymentSession?.clientSecret)) {
        setStripeSecret(paymentSession.clientSecret);
        setIsLoadingPayment(false);

        return navigate(`/${ROUTE_PATHS.checkoutPayment}?orderId=${data?.id}`);
      }
    } else if (isSuccess && 'paymentType' in data && data.paymentType.id === PaymentType.AdvancedPayment && !('status' in data)) {
      // assume advance payment
      setCartItemsQuantity(RESET);
      setCartId(RESET);
      return navigate(`/${ROUTE_PATHS.checkoutConfirm}?orderId=${data?.id}`);
    } else if ((isSuccess && 'status' in data && data.status >= 400) || isError) {
      // assume error
      return navigate(`/${ROUTE_PATHS.checkout}?error=${'status' in data && data.status}`);
    }
  }, [data, isError, isSuccess, navigate, paymentSession?.clientSecret, setCartId, setCartItemsQuantity, setStripeSecret]);

  return {
    t,
    cart,
    isLoadingCart,
    isLoadingPayment,
    isDisabledBuyButton,
    checkoutItems,
    handleContinueShopping,
    handleCheckoutClick,
    handleBuyClick
  };
};
