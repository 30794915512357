import FlashMessage from 'components/FlashMessage/FlashMessage';
import Loading from 'components/Loading/Loading';
import { JSX } from 'react';
import { isEmpty } from 'utils/validation';
import MyOrder from '../MyOrder/MyOrder';
import styles from './myOrders.module.scss';
import { useMyOrders } from './useMyOrders';

const MyOrders = (): JSX.Element => {
  const { orders, isLoading, isError, t, isFailedOrder } = useMyOrders();

  return (
    <section className={styles.bMyOrders}>
      {isFailedOrder &&
        <FlashMessage
          extensionClass={styles.flashMessage}
          message={t('errors.orderPaymentFailed')}
          type='error'/>}
      {isLoading &&
        <Loading height={80}/>}
      {(!isLoading && isEmpty(orders) || isError) &&
        t('orders.noOrders')}
      {(!isLoading && orders) &&
        orders.map(order => (
          <MyOrder key={order.id} order={order}/>
        ))}
    </section>
  );
};

export default MyOrders;
