import { atomWithReset, atomWithStorage } from 'jotai/utils';
import { User } from 'types/user';

// Atoms
// These atoms are used to store the state of the application.
// They can be used to store the state of the cart, user, etc.

// pageTitleAtom is used to store the title of the page
export const pageTitleAtom = atomWithReset<string>('Official Merchandise & On-line Shop of Siddharta');

// cartIdAtom is used to store the cart ID
export const cartIdAtom = atomWithStorage<string | undefined>('cartId', undefined);
export const cartItemsQuantityAtom = atomWithStorage<number | undefined>('cartItemsQuantity', undefined);

// userAtom is used to store the user data
export const userAtom = atomWithStorage<User | Record<string, never>>('user', {});

// previousUrlAtom is used to store the previous URL. Mainly used for redirection
export const previousUrlAtom = atomWithReset('');

// modalAtom is used to store the modal state
export const modalAtom = atomWithReset('');

// stripeSecretAtom is used to store the stripe client secret
export const stripeSecretAtom = atomWithReset('');
