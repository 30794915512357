import { useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCategories } from 'services/getCategories/getCategories';
import { useGetUser } from 'services/getUser/getUser';
import { pageTitleAtom, userAtom } from 'store/store';
import { NavigationItem } from 'types/navigation';
import { Viewport } from 'types/viewports';
import { useViewport } from 'utils/useViewport';
import { isNotEmpty, isNotUndefined, isUndefined } from 'utils/validation';
import { URL_LISTINGS_PREFIX } from 'constants/paths';
import { hasAccessToken } from 'constants/requests';

interface UseLayoutReturn {
  i18n: {
    language: string;
    on: (event: string, callback: () => void) => void;
    off: (event: string, callback: () => void) => void;
  };
  pageTitle: string;
  viewport: Viewport | '';
  categories?: NavigationItem[];
}

export const useLayout = (): UseLayoutReturn => {
  const pageTitle = useAtomValue(pageTitleAtom);
  const [storedUser, setStoredUser] = useAtom(userAtom);
  const [categoryId, setCategoryId] = useState(0);
  const prevCategoryRef = useRef<string | null>(null);

  const { i18n } = useTranslation();
  const queryClient = useQueryClient();
  const viewport = useViewport();
  const navigate = useNavigate();
  const { categoryParam } = useParams<{ categoryParam: string }>();

  const { categories, isLoading } = useGetCategories();
  const { user } = useGetUser();

  useEffect(() => {
    if (user && isNotEmpty(user) && hasAccessToken()) {
      setStoredUser(user);
    }
  }, [user, setStoredUser, storedUser]);

  // invalidate queries on language change
  useEffect(() => {
    const handleLanguageChange = async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: ['getCategories'] });
      await queryClient.invalidateQueries({ queryKey: ['getCart'] });
      await queryClient.invalidateQueries({ queryKey: ['getProduct'] });
      await queryClient.invalidateQueries({ queryKey: ['getProducts'] });
      await queryClient.invalidateQueries({ queryKey: ['getFeatured'] });
      await queryClient.invalidateQueries({ queryKey: ['getUserOrder'] });
      await queryClient.invalidateQueries({ queryKey: ['getPaymentMethods'] });
      await queryClient.invalidateQueries({ queryKey: ['getShippingMethods'] });
    };

    i18n.on('languageChanged', handleLanguageChange);

    return (): void => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [categoryParam, i18n, queryClient]);

  // set category
  useEffect(() => {
    const currentCategory = categoryParam || '';
    const currentCategoryId = categories?.find(item =>
      item.slug === `${URL_LISTINGS_PREFIX}/${currentCategory}`
    )?.id;

    const currentChildCategoryId = categories?.find(item =>
      item.children?.find(subItem => subItem.slug.includes(currentCategory))
    )?.children?.find(subItem => subItem.slug === `${URL_LISTINGS_PREFIX}/${currentCategory}`)?.id;

    setCategoryId(currentCategoryId || currentChildCategoryId || 0);
  }, [categories, categoryParam]);


  // update url
  useEffect(() => {
    if (!isLoading && categories) {
      const currentCategory = categoryParam || '';

      const getCurrentItem = categories.find(item => item.slug === `${URL_LISTINGS_PREFIX}/${currentCategory}`);
      const getCurrentChildItem = categories
        .map(item => item.children?.find(subItem => subItem.slug === `${URL_LISTINGS_PREFIX}/${currentCategory}`))
        .find(childItem => isNotUndefined(childItem));

      if (isUndefined(getCurrentItem) || isUndefined(getCurrentChildItem)) {
        const newCategory = categories.find(item => item.id === categoryId);
        const newChildCategory = categories
          .map(item => item.children?.find(subItem => subItem.id === categoryId))
          .find(childItem => isNotUndefined(childItem));

        if (newCategory || newChildCategory) {
          const newSlug = `${(newCategory || newChildCategory)?.slug}`;

          if (newSlug && newSlug !== prevCategoryRef.current) {
            prevCategoryRef.current = newSlug;
            navigate(newSlug);
          }
        }
      }
    }
  }, [categories, categoryId, categoryParam, i18n.language, isLoading, navigate]);

  // set language attribute to html
  document.documentElement.lang = i18n.language;

  return {
    i18n,
    pageTitle,
    viewport,
    categories
  };
};
