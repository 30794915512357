import { JSX } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPage } from 'services/getPage/getPage';
import { objectIsNotEmpty } from 'utils/validation';
import Loading from '../Loading/Loading';
import styles from './staticPage.module.scss';

const StaticPage = (): JSX.Element => {
  const { pageParam } = useParams<{ pageParam: string }>();
  const pageId = pageParam || '';
  const { page, isLoading } = useGetPage(pageId);

  return (
    <article className={styles.bStaticPage}>
      {isLoading || !objectIsNotEmpty(page) ?
        <Loading height={70} /> :
        <>
          <h1 className='h2'>{page?.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: page?.content ? page?.content : '' }}></div>
        </>
      }
    </article>
  );
};

export default StaticPage;
