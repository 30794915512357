import classNames from 'classnames';
import Button from 'components/Button/Button';
import FlashMessage from 'components/FlashMessage/FlashMessage';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { JSX } from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from 'constants/paths';
import { validationRegister } from 'constants/validation';
import styles from './register.module.scss';
import { useRegister } from './useRegister';

const Register = (): JSX.Element => {
  const {
    t,
    isSuccessRegister,
    initialValues,
    setFieldErrorRef,
    handleSubmit
  } = useRegister();

  return (
    <section className={classNames(styles.bRegister, 'formCenter')}>
      <h1 className='h2'>{t('login.register')}</h1>
      {isSuccessRegister &&
        <FlashMessage
          message={t('login.textRegisterSuccess')}
          type='success'/>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationRegister(t)}
        onSubmit={handleSubmit}
        validateOnMount={true}>
        {({ isSubmitting, isValid, setFieldError }) => {
          setFieldErrorRef.current = setFieldError;

          return (
            <Form className='form'>
              <Field
                autoComplete='given-name'
                type='text'
                name='firstName'
                className='input'
                id='firstName'
                placeholder={t('address.firstName')}/>
              <ErrorMessage
                name='firstName'
                component='p'
                className='inlineError'/>

              <Field
                autoComplete='family-name'
                type='text'
                name='lastName'
                className='input'
                id='lastName'
                placeholder={t('address.lastName')}/>
              <ErrorMessage
                name='lastName'
                component='p'
                className='inlineError'/>

              <Field
                autoComplete='email'
                type='email'
                name='email'
                className='input'
                id='email'
                placeholder={t('login.email')}/>
              <ErrorMessage
                name='email'
                component='p'
                className='inlineError'/>

              <Field
                autoComplete='off'
                name='password'
                className='input'
                id='password'
                placeholder={t('login.password')}
                type='password'/>
              <ErrorMessage
                name='password'
                component='p'
                className='inlineError'/>
              <p>{t('login.passwordConditions', { min: 10 })}</p>

              <div className='checkboxWrapper'>
                <Field
                  type='checkbox'
                  name='checkTerms'
                  id='register-terms'
                  className='checkbox'/>
                <label
                  htmlFor='register-terms'
                  className='checkLabel'>
                  <span className='labelText'>
                    {t('login.termsLabel')}
                  </span>
                </label>
                <ErrorMessage
                  name='checkTerms'
                  component='p'
                  className='inlineError'/>
              </div>

              <Button
                type='submit'
                disabled={isSubmitting || !isValid}
                extensionClass='submitButton'>
                {t('login.register')}
              </Button>

              <Link to={`/${ROUTE_PATHS.login}`} className='nextToSubmitLink'>
                {t('login.title')}
              </Link>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default Register;
