import { useEffect, useState } from 'react';
import { useGetUserOrders } from 'services/getUserOrders/getUserOrders';
import { UserOrder } from 'types/order';
import { useLocationParams } from 'utils/navigation';

interface UseCheckoutConfirmReturn {
  order?: UserOrder;
  isLoadingOrder?: boolean;
  isAdvancedPaymentOrder?: boolean;
}

export const useCheckoutConfirm = (): UseCheckoutConfirmReturn => {
  const orderId = useLocationParams('orderId');
  const { orders, isLoading } = useGetUserOrders(orderId);
  const [isAdvancedPaymentOrder, setIsAdvancedPaymentOrder] = useState(false);

  useEffect(() => {
    setIsAdvancedPaymentOrder((orders as UserOrder)?.paymentType?.id === 2);
  }, [orders]);

  return {
    isLoadingOrder: isLoading,
    order: orders as UserOrder,
    isAdvancedPaymentOrder
  };
};
