import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { addActiveClass } from 'utils/navigation';
import { NAVIGATION_ACCOUNT } from 'constants/navigation';
import styles from './sidebar.module.scss';

const Sidebar = (): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation('global');

  return (
    <aside className={styles.bSidebar}>
      <ul className={styles.sidebarList}>
        {NAVIGATION_ACCOUNT.map(item => (
          <li className={styles.sidebarItem} key={item.id}>
            <Link
              to={item.slug}
              className={addActiveClass(item.slug, location, styles.sidebarLink)}>
              {t(item.name)}
            </Link>
          </li>))}
      </ul>
    </aside>
  );
};

export default Sidebar;
