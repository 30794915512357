import { JSX } from 'react';
import { Link } from 'react-router-dom';
import { NavigationItem } from 'types/navigation';
import { isNotUndefined } from 'utils/validation';
import { NAVIGATION_ACCOUNT } from 'constants/navigation';
import { hasAccessToken } from 'constants/requests';
import BackgroundCover from '../../BackgroundCover/BackgroundCover';
import Icon from '../../Icon/Icon';
import LanguagePicker from '../../LanguagePicker/LanguagePicker';
import QuickLinks from '../../QuickLinks/QuickLinks';
import NavItemStore from '../NavItemStore/NavItemStore';
import styles from './mobileNavigation.module.scss';
import { useMobileNavigation } from './useMobileNavigation';

interface MobileNavigationProps {
  categories?: NavigationItem[];
}

const MobileNavigation = ({ categories }: MobileNavigationProps): JSX.Element => {
  const {
    isOpen,
    setIsOpen,
    activeFirstLevel,
    mobileNavClasses,
    toggleMobileNav,
    toggleFirstLevel,
    navLinkClasses,
    navListAccountClasses,
    t
  } = useMobileNavigation();

  const handleLinkClick = (itemId: number): void => {
    toggleFirstLevel(itemId);
    toggleMobileNav();
  };

  const renderFirstLevel = (item: NavigationItem): JSX.Element => {
    return (
      item.children ?
        <button
          type='button'
          onClick={(): void => toggleFirstLevel(item.id)}
          className={navLinkClasses(item.id)}>
          {t(item.name)}
        </button> :
        <Link
          to={item.slug}
          className={navLinkClasses(item.id)}
          onClick={() => handleLinkClick(item.id)}>
          {t(item.name)}
        </Link>
    );
  };

  const renderMenuButton = (): JSX.Element => (
    <button
      type='button'
      className={styles.menuIcon}
      onClick={toggleMobileNav}
      aria-label={t('navigation.toggleMenu')}>
      <Icon
        iconName={isOpen ? 'close' : 'menu'}
        iconTitle={t('navigation.toggleMenu')}
        extensionClass={styles.menuIcon}/>
    </button>
  );

  return (
    <section className={mobileNavClasses}>
      {renderMenuButton()}
      <BackgroundCover isOpen={isOpen} setIsOpen={setIsOpen}/>
      <nav role='navigation' className={styles.navigation}>
        <Icon
          iconName='flame'
          extensionClass={styles.flameIcon}
          iconTitle={t('pageTitle') + ' ' + t('pageTitleSub')}/>
        {renderMenuButton()}
        <div className={styles.headerLinks}>
          <LanguagePicker extensionClass='xMobileNav'/>
          <QuickLinks isMobileNav isMobileSearchClosed={!isOpen}/>
        </div>
        {isNotUndefined(categories) &&
        <ul className={styles.navList}>
          <NavItemStore extendOnClick={() => handleLinkClick(1)}/>
          {categories.map(item => (
            <li key={item.id} className={styles.navItem}>
              {renderFirstLevel(item)}
              {item.children && activeFirstLevel === item.id && (
                <ul className={styles.subNavList}>
                  {item.children.map(child => (
                    <li key={child.id} className={styles.subNavItem}>
                      <Link to={child.slug} onClick={toggleMobileNav}>{t(child.name)}</Link>
                    </li>
                  ))}
                </ul>)}
            </li>))}
        </ul>}
        {hasAccessToken() &&
          <ul className={navListAccountClasses}>
            {NAVIGATION_ACCOUNT.map(item => (
              <li key={item.id} className={styles.navItem}>
                <Link
                  to={item.slug}
                  onClick={() => handleLinkClick(item.id)}
                  className={navLinkClasses(item.id)}>{t(item.name)}</Link>
              </li>))}
          </ul>}
      </nav>
    </section>
  );
};

export default MobileNavigation;
