import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { useAtom, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { queryMutation } from 'services/services';
import { cartIdAtom, cartItemsQuantityAtom } from 'store/store';
import { AddToCart } from 'types/cart';
import { GenericMutationResponse } from 'types/requests';
import { isNotEmpty } from 'utils/validation';

interface UseUpdateCartReturn extends GenericMutationResponse<AddToCart> {
  addToCart: UseMutateFunction<Response, Error, AddToCart, unknown>;
}

export const useAddToCartRequest = (): UseUpdateCartReturn => {
  const [cartId, setCartId] = useAtom(cartIdAtom);
  const setCartItemsQuantity = useSetAtom(cartItemsQuantityAtom);

  const { mutate, ...args } = useMutation({
    mutationFn: (addToCart: AddToCart) => {
      if (cartId && isNotEmpty(addToCart.items)) {
        return queryMutation(`shopping-cart/${cartId}/items?`, 'POST', addToCart.items[0]);
      }
      return queryMutation('shopping-cart?', 'POST', addToCart);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (data: any) => {
      if (data?.items && !data?.product) {
        // adding to cart without a cart id
        setCartId(data.id);
      } else if (data?.product) {
        // adding to cart with a cart id
        // Do nothing as cartId remains the same
      } else if (data?.status === 400) {
        // handle inlined error like quantity
        return data;
      } else {
        // endpoint errored out, reset cart id
        setCartId(RESET);
      }

      if (data?.itemsQuantity || data?.items) {
        scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }

      // update cart quantity
      if (data?.itemsQuantity || data?.items) {
        setCartItemsQuantity(data?.itemsQuantity || data?.items[0]?.quantity);
      }
    }
  });

  return {
    addToCart: mutate,
    ...args
  };
};
