import classNames from 'classnames';
import Button from 'components/Button/Button';
import FlashMessage from 'components/FlashMessage/FlashMessage';
import Icon from 'components/Icon/Icon';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { JSX } from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from 'constants/paths';
import { validationResetPassword } from 'constants/validation';
import styles from './resetPassword.module.scss';
import { useResetPassword } from './useResetPassword';

const ResetPassword = (): JSX.Element => {
  const {
    t,
    initialValues,
    setFieldErrorRef,
    isSuccessRequest,
    handleSubmit
  } = useResetPassword();

  return (
    <section className={classNames(styles.bResetPassword, 'formCenter')}>
      <h1 className='h2'>{t('login.resetPassword')}</h1>
      {isSuccessRequest &&
        <FlashMessage
          message={t('login.resetPasswordSuccess')}
          type='success'/>}
      <Formik
        initialValues={initialValues}
        validationSchema={validationResetPassword(t)}
        onSubmit={handleSubmit}
        validateOnMount={true}>
        {({ isSubmitting, isValid, setFieldError }) => {
          setFieldErrorRef.current = setFieldError;

          return (
            <Form className='form'>
              <Field
                autoComplete='off'
                name='code'
                className='input'
                id='password'
                placeholder={t('login.resetCode')}/>
              <ErrorMessage
                name='code'
                component='p'
                className='inlineError'/>

              <Field
                autoComplete='new-password'
                name='password'
                className='input'
                id='password'
                placeholder={t('login.newPassword')}
                type='password'/>
              <ErrorMessage
                name='password'
                component='p'
                className='inlineError'/>

              <Field autoComplete='off'
                name='passwordConfirmation'
                className='input'
                id='passwordConfirm'
                placeholder={t('login.passwordConfirm')}
                type='password'/>
              <ErrorMessage
                name='passwordConfirmation'
                component='p'
                className='inlineError'/>
              <p className={styles.passwordConditions}>{t('login.passwordConditions', { min: 10 })}</p>

              <div className={styles.actionWrapper}>
                <Link to={`/${ROUTE_PATHS.login}`} className={styles.loginLink}>
                  <Icon iconName='chevron-left' />
                  <span className={styles.loginLinkText}>
                    {t('login.title')}
                  </span>
                </Link>

                <Button
                  type='submit'
                  disabled={isSubmitting || !isValid || isSuccessRequest}
                  extensionClass='submitButton'>
                  {t('login.submit')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default ResetPassword;
