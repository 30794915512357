import Breadcrumb from 'components/_navi/Breadcrumb/Breadcrumb';
import Availability from 'components/Availability/Availability';
import Button from 'components/Button/Button';
import Gallery from 'components/Gallery/Gallery';
import Loading from 'components/Loading/Loading';
import RadioSelect from 'components/RadioSelect/RadioSelect';
import SelectBox from 'components/SelectBox/SelectBox';
import { Dispatch, JSX, SetStateAction } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedNumber } from 'react-intl';
import { ProductAttribute } from 'types/products';
import { objectIsNotEmpty } from 'utils/validation';
import { QUANTITY_OPTIONS } from 'constants/sorting';
import styles from './productOverview.module.scss';
import { useAddToCart } from './useAddToCart';
import { useProductOverview } from './useProductOverview';

const ProductOverview = (): JSX.Element => {
  const {
    product,
    isLoading,
    isProductError,
    t,
    navigate,
    isDisabled } = useProductOverview();

  const {
    setSizeId,
    sizeId,
    setColorId,
    colorId,
    isAdded,
    isIncomplete,
    addToCartError,
    quantity,
    maxQuantity,
    handleQuantityChange,
    handleAddToCart
  } = useAddToCart({
    product
  });

  if (isProductError) {
    // exit to previous page
    return <>{navigate(-1)}</>;
  }

  const renderRadioSelect = (attribute: ProductAttribute, setValue: Dispatch<SetStateAction<string>>, value: string): JSX.Element => (
    <RadioSelect
      key={attribute.id}
      options={attribute.values}
      title={attribute.name}
      setValue={setValue}
      radioName={attribute.name}
      value={value}/>
  );

  // TODO: if quantity selected is more than max quantity, set max quantity to select
  // TODO: messaging why quantity is disabled

  return (
    <>
      {objectIsNotEmpty(product?.meta) &&
        <Helmet>
          <meta name='description' content={product?.meta.description} />
          <meta name='keywords' content={product?.meta.keywords} />
        </Helmet>}
      <article className={styles.bProductOverview}>
        <Breadcrumb categories={product?.categories} />
        {isLoading &&
          <Loading height={100} />}
        {(!isLoading && product) &&
        <>
          <Gallery images={product.images} productName={product.name}/>
          <section className={styles.infoWrapper}>
            <em className={styles.type}>{product.type} • {product.sku}</em>
            <h1 className={styles.title}>{product.name}</h1>
            {product.price &&
              <em className={styles.price}>
                <FormattedNumber
                  value={Number(product.price.value)}
                  style='currency'
                  currency={product.price.currencyCode}/>
              </em>}
            {product?.attributes.map(attribute =>
              renderRadioSelect(attribute, attribute.id === 1 ? setSizeId : setColorId,
                attribute.id === 1 ? sizeId : colorId)
            )}
            {product.description &&
              <p className={styles.description}
                dangerouslySetInnerHTML={{ __html: product.description }}></p>}
            {product.availability &&
              <Availability
                availability={product.availability}
                title={t('product.availability.title')} />}
            <SelectBox
              id='quantity-select'
              options={QUANTITY_OPTIONS(maxQuantity).slice(1)}
              classes={styles.selectWrapper}
              label={t('product.quantity')}
              selectedOption={quantity}
              defaultValue={QUANTITY_OPTIONS(maxQuantity)[1]}
              placeholder={t('product.quantitySelect')}
              hasError={!!addToCartError}
              disabled={isDisabled || maxQuantity === 0}
              onChange={handleQuantityChange}/>
            {addToCartError &&
              <p className={styles.addToCarError}>{t(addToCartError)}</p>}
            <Button
              extensionClass='xFullWidth'
              disabled={isAdded || isDisabled || isIncomplete || maxQuantity === 0}
              icon={isAdded ? 'check' : 'shopping-cart-add'}
              onClick={handleAddToCart}>
              {t('ctaAddToCart')}</Button>
          </section>
        </>}
      </article>
    </>
  );
};

export default ProductOverview;
