import { TFunction } from 'i18next';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { useGetProduct } from 'services/getProduct/getProduct';
import { pageTitleAtom } from 'store/store';
import { Product } from 'types/products';
import { objectIsNotEmpty } from 'utils/validation';

interface UseProductOverviewReturn {
  t: TFunction<'global', undefined>;
  navigate: NavigateFunction;
  isLoading?: boolean;
  isProductError?: boolean;
  product?: Product;
  isDisabled?: boolean;
}

export const useProductOverview = (): UseProductOverviewReturn => {
  const { t } = useTranslation('global');
  const setPageTitle = useSetAtom(pageTitleAtom);
  const { slugParam } = useParams<{ slugParam: string }>();
  const navigate = useNavigate();

  const id = slugParam?.split('-').shift();
  const { product, isLoading, isError } = useGetProduct(id);

  const isDisabled = product && (product.availability === 'out_of_stock' || product.outOfStock) || isLoading;
  const metaTitle = `${product?.meta.title} - ${product?.type} | ${t('pageTitle')} ${t('pageTitleSub')}`;

  useEffect(() => {
    if (objectIsNotEmpty(product?.meta)) {
      setPageTitle(metaTitle);
    }
  }, [metaTitle, product?.meta, setPageTitle]);

  return {
    t,
    product,
    isLoading,
    isProductError: isError,
    navigate,
    isDisabled
  };
};
