import VIEWPORTS from '../constants/viewports';
import { Viewport } from '../types/viewports';

const getBreakpointsFromHtml = (viewportName: Viewport): number => {
  const breakpoints: Record<Viewport, number> = {
    [Viewport.MOBILE]: 0,
    [Viewport.TABLET]: 0,
    [Viewport.DESKTOP]: 0
  };
  const root = document.querySelector(':root');

  if (root) {
    breakpoints[viewportName] = Number(getComputedStyle(root).getPropertyValue(`--break-${viewportName}`));
  }

  return breakpoints[viewportName];
};

export const getViewportFromScreenSize = (): Viewport => {
  const windowWidth = window.innerWidth;

  if (windowWidth <= getBreakpointsFromHtml(<Viewport>VIEWPORTS.MOBILE)) {
    return <Viewport>VIEWPORTS.MOBILE;
  } else if (windowWidth > getBreakpointsFromHtml(<Viewport>VIEWPORTS.MOBILE) && windowWidth <= getBreakpointsFromHtml(<Viewport>VIEWPORTS.TABLET)) {
    return <Viewport>VIEWPORTS.TABLET;
  } else {
    return <Viewport>VIEWPORTS.DESKTOP;
  }
};
