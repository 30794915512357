import { TFunction } from 'i18next';
import { NavigationItem, NavigationItemLanguage, NavigationItemQuickLink } from 'types/navigation';
import { Viewport } from 'types/viewports';
import { ROUTE_PATHS, URL_PAGES_PREFIX } from './paths';
import { hasAccessToken } from './requests';
import VIEWPORTS from './viewports';

interface NavigationQuickLinksProps {
  viewport: Viewport | '';
  isMobileNav?: boolean;
}

export const NAVIGATION_QUICK = ({ viewport, isMobileNav }: NavigationQuickLinksProps): NavigationItemQuickLink[] => ([
  ...(viewport === VIEWPORTS.DESKTOP || isMobileNav) ? [
    {
      id: 5,
      name: 'headerLinks.search',
      icon: 'search'
    },
    {
      id: 6,
      name: hasAccessToken() ? 'login.myAccount' : 'headerLinks.login',
      slug: hasAccessToken() ? ROUTE_PATHS.accountData : ROUTE_PATHS.login,
      icon: 'account'
    }] : [],
  ...(!isMobileNav) ? [{
    id: 7,
    name: 'headerLinks.cart',
    slug: ROUTE_PATHS.cart,
    icon: 'shopping-cart'
  }] : []
]);

export const NAVIGATION_LANGUAGE: NavigationItemLanguage[] = [
  {
    id: 8,
    name: 'languageSelect.eng',
    language: 'en'
  },
  {
    id: 9,
    name: 'languageSelect.slo',
    language: 'sl'
  }
];

export const NAVIGATION_PAGES = (t: TFunction, language: string): NavigationItem[] => {
  return ([
    {
      id: 1,
      name: t('footer.terms'),
      slug: `${URL_PAGES_PREFIX}/${t('footer.termsSlug')}`
    },
    {
      id: 2,
      name: t('footer.generalTerms'),
      slug: `${URL_PAGES_PREFIX}/${t('footer.generalTermsSlug')}`
    },
    {
      id: 3,
      name: t('footer.privacy'),
      slug: `${URL_PAGES_PREFIX}/${t('footer.privacySlug')}`
    },
    {
      id: 4,
      name: t('footer.contact'),
      slug: `https://www.siddharta.net/${language}/contact`
    }
  ]);
};

export const NAVIGATION_ACCOUNT: NavigationItem[] = [
  {
    id: 10,
    name: 'login.accountData',
    slug: ROUTE_PATHS.accountData
  },
  {
    id: 11,
    name: 'login.addresses',
    slug: ROUTE_PATHS.addresses
  },
  {
    id: 12,
    name: 'login.myOrders',
    slug: ROUTE_PATHS.myOrders
  },
  {
    id: 13,
    name: 'login.logout',
    slug: `/${ROUTE_PATHS.logout}`
  }
];
