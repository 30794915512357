import { JSX } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { Product } from 'types/products';
import styles from './gallery.module.scss';
import { useGallery } from './useGallery';

interface GalleryProps {
  images: Product['images'];
  productName?: string;
}

const Gallery = ({ images, productName }: GalleryProps): JSX.Element => {
  const {
    mainSliderRef,
    thumbsSliderRef,
    mainSliderSettings,
    thumbsSliderSettings,
    hasMultipleImages,
    handleThumbsButtonClick,
    handleMainSliderBeforeChange
  } = useGallery(images);

  return (
    <figure className={styles.bGallery}>
      <Slider
        className={styles.mainSlider}
        ref={(slider): Slider | null => (mainSliderRef.current = slider)}
        {...mainSliderSettings}
        beforeChange={handleMainSliderBeforeChange}>
        {images.map((image, index) => (
          <Zoom
            key={index}
            zoomMargin={40}
            zoomImg={{ src: image.large }}>
            <img
              className={styles.mainImg}
              src={image.medium}
              alt={productName}
              width={1280}/>
          </Zoom>
        ))}
      </Slider>
      {hasMultipleImages &&
        <Slider
          className={styles.thumbsSlider}
          ref={(slider): Slider | null => (thumbsSliderRef.current = slider)}
          {...thumbsSliderSettings}>
          {images.map((thumb, index) => (
            <button
              key={index}
              className={styles.thumbImgButton}
              onClick={() => handleThumbsButtonClick(index)}>
              <img
                src={thumb.small}
                alt={productName}
                className={styles.thumbImg}
                width={640}
                height={640}/>
            </button>
          ))}
        </Slider>}
    </figure>
  );
};

export default Gallery;
