import AccountData from 'components/_account/AccountData/AccountData';
import Addresses from 'components/_account/Addresses/Addresses';
import EditAddress from 'components/_account/EditAddress/EditAddress';
import ForgotPassword from 'components/_account/ForgotPassword/ForgotPassword';
import Login from 'components/_account/Login/Login';
import Logout from 'components/_account/Logout/Logout';
import MyAccount from 'components/_account/MyAccount/MyAccount';
import MyOrders from 'components/_account/MyOrders/MyOrders';
import Register from 'components/_account/Register/Register';
import ResetPassword from 'components/_account/ResetPassword/ResetPassword';
import Layout from 'components/_Layout/Layout';
import CartOverview from 'components/_overviews/CartOverview/CartOverview';
import CheckoutConfirm from 'components/_overviews/checkout/CheckoutConfirm/CheckoutConfirm';
import CheckoutOverview from 'components/_overviews/checkout/CheckoutOverview/CheckoutOverview';
import CheckoutPayment from 'components/_overviews/checkout/CheckoutPayment/CheckoutPayment';
import CheckoutSummary from 'components/_overviews/checkout/CheckoutSummary/CheckoutSummary';
import ListingOverview from 'components/_overviews/ListingOverview/ListingOverview';
import ProductOverview from 'components/_overviews/ProductOverview/ProductOverview';
import SearchOverview from 'components/_overviews/SearchOverview/SearchOverview';
import StoreOverview from 'components/_overviews/StoreOverview/StoreOverview';
import ErrorPage from 'components/ErrorPage/ErrorPage';
import StaticPage from 'components/StaticPage/StaticPage';
import { createBrowserRouter } from 'react-router-dom';
import { ROUTE_PATHS } from './paths';

export const ROUTES = createBrowserRouter([
  {
    path: ROUTE_PATHS.home,
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ROUTE_PATHS.storeOverview,
        index: true,
        element: <StoreOverview />
      },
      {
        path: ROUTE_PATHS.search,
        element: <SearchOverview />
      },
      {
        path: ROUTE_PATHS.listingOverview,
        element: <ListingOverview />
      },
      {
        path: ROUTE_PATHS.productOverview,
        element: <ProductOverview />
      },
      {
        path: ROUTE_PATHS.staticPage,
        element: <StaticPage />
      },
      {
        path: ROUTE_PATHS.cart,
        element: <CartOverview />
      },
      {
        path: ROUTE_PATHS.checkout,
        element: <CheckoutOverview />
      },
      {
        path: ROUTE_PATHS.checkoutSummary,
        element: <CheckoutSummary />
      },
      {
        path: ROUTE_PATHS.checkoutConfirm,
        element: <CheckoutConfirm />
      },
      {
        path: ROUTE_PATHS.checkoutPayment,
        element: <CheckoutPayment />
      },
      {
        path: ROUTE_PATHS.login,
        element: <Login />
      },
      {
        path: ROUTE_PATHS.logout,
        element: <Logout />
      },
      {
        path: ROUTE_PATHS.forgotPassword,
        element: <ForgotPassword />
      },
      {
        path: ROUTE_PATHS.resetPassword,
        element: <ResetPassword />
      },
      {
        path: ROUTE_PATHS.register,
        element: <Register />
      },
      {
        path: ROUTE_PATHS.myAccount,
        element: <MyAccount />,
        children: [
          {
            path: `${ROUTE_PATHS.accountData}`,
            index: true,
            element: <AccountData />
          },
          {
            path: `${ROUTE_PATHS.addresses}`,
            element: <Addresses />
          },
          {
            path: `${ROUTE_PATHS.editAddress}/:addressIdParam`,
            element: <EditAddress />
          },
          {
            path: `${ROUTE_PATHS.myOrders}`,
            element: <MyOrders />
          }
        ]
      }
    ]
  }
]);
