import { useQuery } from '@tanstack/react-query';
import { GetProducts } from 'types/requests';
import { fetchProductSearch } from 'utils/fetch';
import { URL_PRODUCT_PREFIX } from 'constants/paths';

export const useGetProductSearch = (query: string): GetProducts => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ['getProductSearch', query],
    queryFn: () => fetchProductSearch(query)
  });

  const updatedData = data?.map(product => ({
    ...product,
    slug: `/${URL_PRODUCT_PREFIX}/${product.id}-${product.slug}`
  }));

  return {
    isLoading,
    products: updatedData,
    isError
  };
};
