import classNames from 'classnames';
import { JSX } from 'react';
import styles from './flashMessage.module.scss';

interface FlashMessageProps {
  message: string;
  details?: string;
  type?: 'success' | 'error';
  extensionClass?: string;
}

const FlashMessage = ({ message, details, type, extensionClass }: FlashMessageProps): JSX.Element => {
  const flashMessageClasses = classNames(styles.bFlashMessage, extensionClass, {
    [styles.xError]: type === 'error',
    [styles.xSuccess]: type === 'success'
  });

  return (
    <p className={flashMessageClasses}>
      <strong className={styles.title}>{message}</strong>
      {details}
    </p>
  );
};

export default FlashMessage;
