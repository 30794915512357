import { useQuery } from '@tanstack/react-query';
import { GetShippingMethods } from 'types/requests';
import { fetchShippingMethods } from 'utils/fetch';
import { hasAccessToken } from 'constants/requests';

export const useShippingMethods = (countryId?: string): GetShippingMethods => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ['getShippingMethods', countryId],
    queryFn: () => fetchShippingMethods(countryId),
    enabled: !!countryId && hasAccessToken()
  });

  return {
    shippingMethods: data,
    isLoading,
    isError
  };
};
