import { TFunction } from 'i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetCart } from 'services/getCart/getCart';
import { cartIdAtom, cartItemsQuantityAtom, pageTitleAtom, previousUrlAtom, userAtom } from 'store/store';
import { Cart } from 'types/cart';
import { useLocationParams } from 'utils/navigation';
import { isNotEmpty } from 'utils/validation';
import { ROUTE_PATHS } from 'constants/paths';
import { isLoggedIn } from 'constants/requests';

interface UseCartOverviewReturn {
  t: TFunction<'global', undefined>;
  cart?: Cart;
  isLoadingCart?: boolean;
  isFailedOrder: boolean;
  handleCheckoutClick: () => void;
}

export const useCartOverview = (): UseCartOverviewReturn => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const storedUser = useAtomValue(userAtom);
  const setPreviousUrl = useSetAtom(previousUrlAtom);
  const setPageTitle = useSetAtom(pageTitleAtom);
  const cartId = useAtomValue(cartIdAtom);
  const { cart, isSuccess, isLoadingCart, isError } = useGetCart(cartId);
  const setCartItemsQuantity = useSetAtom(cartItemsQuantityAtom);

  const errorParam = useLocationParams('error');
  const [isFailedOrder, setIsFailedOrder] = useState(false);

  useEffect(() => {
    setIsFailedOrder(Boolean(errorParam));
  }, [errorParam]);

  useEffect(() => {
    if (isSuccess && isNotEmpty(cart?.items)) {
      // filter out item.outOfStock
      const filteredItems = cart?.items.filter(item => !item.outOfStock);
      const quantity = filteredItems?.reduce((acc, item) => acc + item.quantity, 0);

      setCartItemsQuantity(quantity);
    } else if (isSuccess && cart?.items.length === 0) {
      setCartItemsQuantity(0);
    } else if (isError) {
      setCartItemsQuantity(RESET);
    } else {
      setCartItemsQuantity(RESET);
    }
  }, [cart, isError, isSuccess, setCartItemsQuantity]);

  useEffect(() => {
    const metaTitle = `${t('cart.title')} | ${t('pageTitle')} ${t('pageTitleSub')}`;

    setPageTitle(metaTitle);
  }, [setPageTitle, t]);

  // sort cart items, put out of stock items at the end
  if (isSuccess && isNotEmpty(cart?.items)) {
    cart?.items.sort((a, b) => {
      if (a.outOfStock && !b.outOfStock) {
        return 1;
      }
      if (!a.outOfStock && b.outOfStock) {
        return -1;
      }

      return 0;
    });
  }

  const handleCheckoutClick = (): void => {
    if (isLoggedIn(storedUser)) {
      navigate(`/${ROUTE_PATHS.checkout}`);
    } else {
      setPreviousUrl(ROUTE_PATHS.cart);
      navigate(`/${ROUTE_PATHS.login}`);
    }
  };

  return {
    t,
    cart,
    isFailedOrder,
    isLoadingCart,
    handleCheckoutClick
  };
};
