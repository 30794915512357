import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryMutation } from 'services/services';
import { UserOrder } from 'types/order';
import { GenericMutationResponse } from 'types/requests';

interface UseAddUserOrderReturn extends GenericMutationResponse<string> {
  addOrder: UseMutateFunction<Response | UserOrder, Error, string, unknown>;
}

export const useAddUserOrder = (): UseAddUserOrderReturn => {
  const { mutate, ...args } = useMutation({
    mutationFn: (cartId: string) => {
      return queryMutation('user/orders?', 'POST', { shoppingCartId: cartId });
    }
  });

  return {
    addOrder: mutate,
    ...args
  };
};
